const initial = {
    isLoggedIn: false,
    data: {},
    permissions: []
};

const userReducer = (state = initial, { type, payload }) => {
    switch (type) {
        case 'EMPTY_USER':
            return {
                isLoggedIn: false,
                data: {}
            }
        case "SET_USER":
            return payload;
        case "UPDATE_USER":
            return { ...state, data: payload };
        default:
            return state;
    }
}

export default userReducer;