import React, { useState } from "react";
import OpenHours from "../components/OpenHours";
import ImageFade from "../components/ImageFade";
import {
  LogoInstagram,
  LogoLocalisation,
  LogoTelephone,
  LogoSiteWeb,
  LogoFacebook,
} from "../components/SocialsLogo";
import Image from "../components/Image";
import Popover from "@mui/material/Popover";

const BusinessHeader = ({ business }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="flex flex-col bg-white rounded w-full ">
      <div className="relative  flex w-full">
        <Image
          alt=""
          src={business.cover_image}
          size="big"
          className="h-56 object-cover rounded-t w-full"
        />
        <ImageFade />
        <div className="absolute flex items-end  -bottom-6">
          <Image
            alt=""
            src={business.profile_picture}
            size="thumb"
            className=" h-24 w-24 object-cover rounded border-4 border-white mx-3"
          />
          <div className=" mb-6">
            <p className="flex text-white text-left font-marker text-lg ">
              {business.name}
            </p>
            <div
              className="flex"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <text className="flex text-left text-base w-96 p-3">
                  {business.gwigs_pop?.map((b) => b.name + ", ")}
                </text>
              </Popover>
              {business.gwigs_pop?.length > 3 ? (
                <>
                  {business.gwigs_pop?.slice(0, 3).map((b) => (
                    <p className="flex text-conso text-left text-base mr-1">
                      {b.name}
                    </p>
                  ))}
                  <p className="flex text-conso text-left text-base mr-1">
                    +{business.gwigs_pop.length - 3}
                  </p>
                </>
              ) : (
                business.gwigs_pop
                  ?.slice(0, 3)
                  .map((b) => (
                    <p className="flex text-conso text-left text-base mr-1">
                      {b.name}
                    </p>
                  ))
              )}
              <p className="flex text-white text-left text-base ml-1">
                • {business.activity_category_pop?.name}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute flex items-start top-0 right-0 my-2 mx-2">
          <>
            {business.website_links?.instagram ? (
              <a
                href={business.website_links?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <LogoInstagram color="#FF70AC" />
              </a>
            ) : null}
            {business.website_links?.facebook ? (
              <a
                href={business.website_links?.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <LogoFacebook color="#5D5FEF" />
              </a>
            ) : null}
            {business.website_links?.personal ? (
              <a
                href={business.website_links?.personal}
                target="_blank"
                rel="noreferrer"
              >
                <LogoSiteWeb color="#8190B0" />
              </a>
            ) : null}
          </>
        </div>
      </div>
      <div className="flex flex-col mt-6 mb-3">
        <div className="flex mx-4 my-2 text-center text-base ">
          {business.description}
        </div>
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <OpenHours
            open_days={business.open_days}
            open_hours={business.open_hours}
            isClickable={true}
            open_hours_type={business.open_hours_type}
          />
          <div className="flex mx-3 justify-end">
            {business.location?.full ? (
              <a
                href={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  business.location?.full
                }
                target="_blank"
                rel="noreferrer"
              >
                <LogoLocalisation color="#FE7762" />
              </a>
            ) : null}
            {business.phone ? (
              <a
                href={"tel:" + business.phone}
                target="_blank"
                rel="noreferrer"
              >
                <LogoTelephone color="#49CC90" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessHeader;
