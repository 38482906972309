import Image from "./Image";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Text from "./Text";

const PointCard = ({ point }) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div className="flex bg-white rounded">
      <Image
        src={point.cover_image}
        size="big"
        className={
          "object-cover   w-1/4 rounded-l " +
          (isClicked ? " h-60 rounded" : "h-32")
        }
      />
      <div className="flex flex-col w-3/4  p-2 gap-2 ">
        <p className="text-md font-bold line-clamp-1 ">{point.name}</p>
        <p
          className={
            "  overflow-hidden " + (isClicked ? "" : "h-12 overflow-hidden")
          }
        >
          <Text text={point.description} />
        </p>
        {point.description ? (
          <button
            className=" flex self-end items-center rounded bg-background px-3 text-metal"
            onClick={() => setIsClicked(!isClicked)}
          >
            {isClicked ? (
              <>
                voir moins <XIcon className="h-5" />
              </>
            ) : (
              <>
                lire la suite <ChevronDownIcon className="h-5" />
              </>
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PointCard;
