import React, { useState } from "react";
import { Link } from "react-router-dom";
import MairieBanner from "../components/MairieBanner";
import PageLayout from "../components/PageLayout";
import { useSelector } from "react-redux";
import useGetData from "../hooks/useGetData";
import {
  ShoppingCartIcon,
  SunIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import BusinessCard from "../components/BusinessCard";
import LoadingLogo from "../components/LoadingLogo";
import Masonry from "react-masonry-css";
import { Helmet } from "react-helmet";

const AnnuairePage = () => {
  window.scrollTo(0, 0);
  const selectedMairie = useSelector((state) => state.selectedMairie);
  const [filter, setFilter] = useState(["commerce", "artisan"]);

  const { data: business, loading } = useGetData({
    url: "/open/list/business",
    params: {
      params: {
        filter: {
          type: filter,
          gwigs: { $in: selectedMairie.gwigs },
        },
      },
    },
    dependencies: [filter],
  });

  function renderBusiness() {
    var category_tab = [];
    business.map((b) =>
      b.activity_category_pop
        ? category_tab.push(b.activity_category_pop.name)
        : category_tab.push("Autres")
    );
    const category_array = [...new Set(category_tab)].sort();

    return category_array.map((category, i) => (
      <div className="mt-8" key={i}>
        <div className="flex bg-conso rounded h-11 mb-5 justify-center">
          <p className="text-white text-md font-bold self-center">{category}</p>
        </div>
        <Masonry
          breakpointCols={{ 767: 1, default: 2 }}
          className="flex gap-3"
          columnClassName="flex flex-col gap-3"
        >
          {category === "Autres"
            ? business
                .filter((b) => !b.activity_category_pop)
                .map((b) => (
                  <Link
                    to={{ pathname: `/business/${b._id}`, state: { data: b } }}
                    key={b._id}
                  >
                    <BusinessCard business={b} gwig={selectedMairie} />
                  </Link>
                ))
            : business
                .filter((b) => b.activity_category_pop)
                .filter((b) => b.activity_category_pop.name === category)
                .map((b) => (
                  <Link
                    to={{ pathname: `/business/${b._id}`, state: { data: b } }}
                    key={b._id}
                  >
                    <BusinessCard business={b} gwig={selectedMairie} />
                  </Link>
                ))}
        </Masonry>
      </div>
    ));
  }

  function renderFilterButtons() {
    const options = [
      {
        type: ["commerce", "artisan"],
        name: "Commerçants et artisans",
        icon: <ShoppingCartIcon className="flex h-8 text-conso mb-3" />,
        color: "conso",
      },
      {
        type: ["association"],
        name: "Associations",
        icon: <SunIcon className="flex h-8 text-pink mb-3" />,
        color: "pink",
      },
      {
        type: ["service"],
        name: "Infrastructures et services",
        icon: <OfficeBuildingIcon className="flex h-8 text-metal mb-3" />,
        color: "metal",
      },
    ];
    return options.map((option, i) => (
      <button
        key={i}
        onClick={() => setFilter(option.type)}
        className={
          `flex flex-col rounded bg-white md:h-36 md:w-48 w-1/3 gap-1  border-${option.color} shadow-md items-center justify-center p-1 md:p-3 ` +
          (JSON.stringify(filter) === JSON.stringify(option.type)
            ? "border-2"
            : "")
        }
      >
        {option.icon}
        <p className={`text-${option.color}`}>{option.name}</p>
      </button>
    ));
  }

  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez toutes les associations et commerces de la ${selectedMairie.name} sur l'annuaire Gwig!`}
        />
        <title>
          Gwig -{" Annuaire de " + selectedMairie.gwigs_pop[0].name}
        </title>
      </Helmet>
      <div className="flex flex-col w-full">
        <MairieBanner
          mairie={selectedMairie}
          commune={selectedMairie.gwigs_pop[0].slug}
          variant="change"
        />
        <div className="flex font-bold text-md my-4">
          Annuaire de la commune
        </div>
        <div className="flex text-metal text-md my-2">
          Sélectionnez une catégorie à afficher
        </div>
        <div className="flex justify-evenly">{renderFilterButtons()}</div>
        <div className="mt-8">
          {loading ? (
            <LoadingLogo />
          ) : business.length ? (
            renderBusiness()
          ) : (
            <p>Aucun élément à afficher</p>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default AnnuairePage;
