import { useEffect, useState } from 'react';
import axios from "axios"
// import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux"
// import Toast from "react-native-toast-message";


const useGetData = ({ initialValue = [], params = {}, dependencies = [], url = "", bypassDep = false, onError = function () { } }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(initialValue);
    // const location = useLocation();
    const selectedGwigs = useSelector((state) => state.selectedGwigs);

    let dep = []
    if (bypassDep) {
        dep = [...dependencies];
    } else {
        dep = [selectedGwigs, ...dependencies]
    }

    useEffect(() => {
        setLoading(true)
        axios
            .post(url, params)
            .then((res) => {
                if (res.data.success) {
                    setData(res.data.data);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setData(null)
                onError();
                // Toast.show({
                //     type: "error",
                //     text1: "Erreur récupération des données",
                //     text2: err.response?.data.errorMessage,
                // });
            });
    }, dep)


    return { loading, data };
}

export default useGetData;