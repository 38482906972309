import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Text from "./Text";
import Image from "./Image";
import CreatedByDisplay from "./CreatedByDisplay";
import LikesNb from "./LikesNb";
import {
  ArrowRightIcon,
  CalendarIcon,
  ClockIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import Modal from "./Modal";
import { useLocation } from "react-router";

const PostCard = ({ post, cardsize }) => {
  const [showModal, setShowModal] = useState(false);

  return cardsize === "small" ? (
    <div className="bg-white rounded p-5">
      <div className="flex justify-between h-56 flex-col">
        <div className="flex flex-col">
          <CreatedByDisplay business={post.createdBy} />
          <div className="flex text-base mt-3">
            <div>{post.title}</div>
          </div>
        </div>

        <div className="flex items-center justify-between mt-3">
          <p className="text-sm  text-metal  ">
            {moment(post.publishDate).fromNow()}
          </p>
          <LikesNb type="post" likes={post.likes_nb} _id={post._id} />
          <Link
            to={`/post/${post._id}`}
            className="flex justify-self-end rounded px-2 h-8 bg-background  "
          >
            <h1 className="flex items-center text-center text-purple ml-2">
              voir plus <ArrowRightIcon className="h-5 ml-2" />
            </h1>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <>
      {/*big card */}
      {showModal ? <Modal func={setShowModal} item={post} /> : null}
      <div className="flex bg-white rounded justify-between flex-col p-5 w-full">
        <div className="flex flex-col">
          <CreatedByDisplay business={post.createdBy} className="mb-8" />
          <div className="md:flex md:flex-col hidden">
            <h3 className="font-bold mb-5 text-md">{post.title}</h3>
            <Text text={post.text} />
          </div>
          <div className="flex-col md:hidden">
            <h3 className="font-bold mb-5 text-md">{post.title}</h3>
            <h4 className="break-words w-72">{post.text}</h4>
          </div>
          {post.cover_image ? (
            <Image
              alt=""
              src={post.cover_image}
              size="big"
              className="h-48 object-cover rounded mt-5 w-full"
              onClick={() => {
                setShowModal(true);
              }}
            />
          ) : null}
        </div>
        {post?.attachments?.length > 0 ? (
          <div className="flex gap-2 justify-center">
            <AttachCard attachments={post.attachments} />
          </div>
        ) : null}
        <div className="flex items-center justify-between mt-3 my-2 mx-2">
          <LikesNb type="post" likes={post.likes_nb} _id={post._id} />
          <p className="text-sm  text-metal  ">
            {moment(post.publishDate).fromNow()}
          </p>
        </div>
      </div>
    </>
  );
};

const AttachCard = ({ attachments }) => {
  console.log(attachments);
  const location = useLocation();

  return attachments.map((attachment, i) => (
    <>
      {attachment.ref === "Event" ? (
        <Link
          to={{
            pathname: "/evenement/" + attachment.item._id,
          }}
          key={i}
          className="flex rounded bg-background  mt-2"
        >
          <Image
            alt=""
            src={attachment.item.cover_image}
            size="med"
            className=" object-cover rounded-l w-32 h-36"
          />
          <div className="p-3">
            <h1 className="font-bold text-md max-w-title line-clamp-2">
              {attachment.item.title || attachment.item.name}
            </h1>
            <div className="md:flex mt-1">
              <h2 className=" flex text-conso ml-2">
                <CalendarIcon className="h-5 mr-1" />
                {moment(attachment.item.datetime_in).format("dddd DD MMM")}
              </h2>
              <h3 className=" flex text-accent-logo ml-2">
                <ClockIcon className="h-5 mr-1" />
                {moment(attachment.item.datetime_in).format("H:mm")}
                {" - "}
                {moment(attachment.item.datetime_out).format("H:mm")}
              </h3>
            </div>
            <h4 className=" flex text-metal ml-2">
              <LocationMarkerIcon className="h-5 mr-1" />
              {attachment.item.location.city}
            </h4>
          </div>
        </Link>
      ) : null}
      {attachment.ref === "Product" ? (
        <Link
          to={{
            pathname: "/view/product/" + attachment.item._id,
            state: { background: location },
          }}
          key={i}
        >
          <Image
            alt=""
            src={attachment.item.cover_image}
            size="med"
            className=" object-cover rounded w-36 h-36"
          />
        </Link>
      ) : null}
    </>
  ));
};

export default PostCard;
