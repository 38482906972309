import React from "react";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/solid";
import moment from "moment";
import { RefreshIcon, CalendarIcon, ClockIcon } from "@heroicons/react/outline";
import EventCard from "../components/EventCard";
import MapImage from "../components/MapImage";
import ImageFade from "../components/ImageFade";
import CreatedByDisplay from "../components/CreatedByDisplay";
import Image from "../components/Image";
import BackButton from "../components/BackButton";
import LoadingLogo from "../components/LoadingLogo";
import Text from "../components/Text";
import WeatherCard from "../components/WeatherCard";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const EventPage = () => {
  window.scrollTo(0, 0);
  const { id } = useParams();
  const selectedMairie = useSelector((state) => state.selectedMairie);

  const { data: event = {}, loading } = useGetData({
    url: "/open/read/event/" + id,
    params: {
      params: {
        populate: "gwigs createdBy",
      },
    },
  });

  const { data: events = [], loading: loading1 } = useGetData({
    url: "/open/get-events",
    params: {
      params: {
        numberOfDays: 1,
        populate: "createdBy",
      },
    },
    dependencies: [event],
  });

  function renderLastEvents() {
    if (loading1) return <LoadingLogo />;
    return events[0]?.data.map((e, i) => (
      <Link to={`/evenement/${e._id}`} key={i}>
        <EventCard gwig={selectedMairie} cardsize="small" event={e} />
      </Link>
    ));
  }

  if (!event) {
    return (
      <PageLayout loading={loading}>
        <p>Aucun élément trouvé :(</p>
      </PageLayout>
    );
  }
  return (
    <PageLayout loading={loading}>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez l'évènement de la ${selectedMairie.name} sur Gwig!`}
        />
        <title>Gwig - {event.title}</title>
      </Helmet>
      <div>
        <BackButton />

        <div className="relative flex w-full">
          <Image
            src={event.cover_image}
            size="big"
            className="h-96 object-cover rounded w-full"
          />
          <ImageFade className="rounded" />
          <div className="absolute -bottom-1  mx-5 my-3 ">
            <p className="text-white text-left w-full font-marker text-xl font-bold">
              {event.title}
            </p>
            <p className="text-conso text-left w-full text-md font-bold ">
              {event.location ? event.location.city : null}
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:grid grid-cols-2 gap-2 mt-8">
          <div className="flex flex-col">
            <h2 className="font-medium">Date et Heure</h2>
            <DateContainer event={event} />
          </div>
          <div className="flex flex-col">
            <p className="font-medium">Lieu</p>
            {event.location ? (
              <MapImage address={event.location} className="mt-3 h-44" />
            ) : null}
          </div>
        </div>
        <div className="mt-8">
          <h2 className="mb-3 font-medium">Organisateur(s)</h2>
          <CreatedByDisplay
            business={event.createdBy}
            className="bg-white inline-flex p-5 rounded"
          />
        </div>
        <div className="mt-8">
          <h2 className="font-medium mb-3">Détails de l'événement</h2>
          <div className="flex rounded bg bg-white w-full p-5">
            <Text text={event.description} />
          </div>
        </div>

        <div className="flex w-full bg-grey h-0.5 my-16"></div>

        {/* Events suggestions */}
        <h3 className="flex font-bold text-md mb-5">
          Autres événements en ce moment
        </h3>
        <div className="flex flex-col sm:grid grid-cols-2 gap-3 mb-12">
          {renderLastEvents()}
        </div>
      </div>
    </PageLayout>
  );
};

const DateContainer = ({ event }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col rounded h-44 bg-white my-3 p-4 justify-between  ">
      <div className="flex-col gap-3">
        <div className="flex items-center ">
          <CalendarIcon className="h-7 text-purple" />
          <p className="flex text-base  ml-2">
            {"du "}
            {moment(event.datetime_in).format("Do MMM YYYY ")}
            {"au "}
            {moment(event.datetime_out).format("Do MMM YYYY ")}{" "}
          </p>
        </div>
        <div className="flex items-center mt-2">
          <ClockIcon className="h-7 text-purple" />
          <p className="flex text-base ml-2">
            {moment(event.datetime_in).format("HH:mm ")}
            <ArrowRightIcon className="h-5 mx-1" />
            {moment(event.datetime_out).format(" HH:mm")}{" "}
          </p>
        </div>
      </div>
      {event.location ? (
        <WeatherCard event={event} eventday={location?.state?.day} />
      ) : null}

      <div className="flex ">
        {event.isRepeating ? (
          <div className="flex items-center rounded px-2 py-1 w-44 h-8 text-purple bg-background">
            <RefreshIcon className="h-5 mr-1" />
            Tous les
            {
              [
                " Lundis",
                " Mardis",
                " Mercredis",
                " Jeudis",
                " Vendredis",
                " Samedis",
                " Dimanches",
              ][event.repeats_on]
            }
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EventPage;

// <div className="flex items-center justify-between my-2 mx-2">
//         <h2 className="flex text-base text-white">Date et heure</h2>
// {event.isRepeating ? (
//   <div className="flex items-center rounded px-2 text-sm text-purple bg-white">
//     <RefreshIcon className="h-4 mr-1" />
//     Tous les
//     {
//       [
//         " Lundis",
//         " Mardis",
//         " Mercredis",
//         " Jeudis",
//         " Vendredis",
//         " Samedis",
//         " Dimanches",
//       ][event.repeats_on]
//     }
//   </div>
// ) : null}
//       </div>
//       <p className="flex text-base text-white mt-2 mx-2">
//         {"du "}
//         {moment(event.datetime_in).format("Do MMM YYYY ")}
//         {"au "}
//         {moment(event.datetime_out).format("Do MMM YYYY ")}
//       </p>
//       <p className="flex text-base text-white mb-2 mx-2">
//         {moment(event.datetime_in).format("HH:mm ")}
//         <ArrowRightIcon className="h-5 mx-1" />
//         {moment(event.datetime_out).format(" HH:mm")}
//       </p>
