import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ChevronUpIcon } from "@heroicons/react/solid";
import moment from "moment";

const OpenHours = ({ open_days, open_hours, isClickable, open_hours_type }) => {
  var heurelocale = timeStringToFloat(moment().format("LT"));
  var daysopen = open_days; //open_days [true,false,false,false,false,false,false]
  var hours_open = open_hours;
  const today = moment().format("d") - 1; //aujourd'hui

  const [clicked, setclicked] = useState(false);
  const week = [
    " Lundi",
    " Mardi",
    " Mercredi",
    " Jeudi",
    " Vendredi",
    " Samedi",
    " Dimanche",
  ];

  var day = today;
  var relativeWeek = [];
  for (let index = 0; index < 7; index++) {
    if (day === 7) {
      day = 0;
    }
    relativeWeek.push(day);
    day++;
  }

  function timeStringToFloat(time) {
    //convertit en format h.m
    var hoursMinutes = time.split(":");
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  function timeFloattoString(time) {
    //convertit en format h:m
    var hours = Math.trunc(time);
    var min = (time % 1) * 60;
    if (min === 0) {
      return hours + "h";
    }
    return hours + "h" + min;
  }

  function renderWeekSchedule() {
    return relativeWeek.map((d) => (
      <div className="grid grid-cols-2 items-center w-80">
        <div className={"flex mb-1 " + (today === d ? "font-bold" : null)}>
          {week[d]}
        </div>
        {daysopen[d] ? (
          <div className={"flex " + (today === d ? "font-bold" : null)}>
            {hours_open[d].map((h) => (
              <div className="ml-2">
                {timeFloattoString(h[0])}
                {"-"}
                {timeFloattoString(h[1])}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex ml-2"> fermé</div>
        )}
      </div>
    ));
  }

  function renderNextOpen() {
    var i = today + 1; //demain
    for (let j = 0; j < 7; j++) {
      if (daysopen[i]) {
        return (
          "• Ouvre " + week[i] + " à " + timeFloattoString(hours_open[i][0][0])
        );
      } else {
        i++;
        if (i === 7) {
          i = 0;
        }
      }
    }
  }

  function renderIsOpen() {
    if (!daysopen[today]) {
      return (
        <>
          <p className="flex ml-1 font-bold text-red-500 ">Fermé aujourd'hui</p>
          <p className="flex ml-1">{renderNextOpen()}</p>
        </>
      );
    }
    if (daysopen[today]) {
      //ouvert aujourd'hui
      for (let i = 0; i < hours_open[today].length; i++) {
        if (heurelocale < hours_open[i][i][0]) {
          if (hours_open[i][i][0] - heurelocale <= 1) {
            return (
              <>
                <p className="flex ml-1 font-bold text-green">
                  • Ouvre bientôt
                </p>
                <p className="flex ml-1 ">
                  • dans {Math.round((hours_open[i][i][0] - heurelocale) * 60)}{" "}
                  minutes
                </p>
              </>
            );
          }
          return (
            <>
              <p className="flex ml-1 font-bold text-red-500">Fermé</p>
              <p className="flex ml-1 ">
                • Ouvre à {timeFloattoString(hours_open[i][i][0])}
              </p>
            </>
          );
        }
        if (
          hours_open[i][i][0] <= heurelocale &&
          heurelocale <= hours_open[i][i][1]
        ) {
          if (hours_open[i][i][1] - heurelocale <= 1) {
            return (
              <>
                <p className="flex ml-1 font-bold text-conso">Ferme bientôt</p>
                <p className="flex ml-1 ">
                  • dans {Math.round((hours_open[i][i][1] - heurelocale) * 60)}{" "}
                  minutes
                </p>
              </>
            );
          }
          return (
            <>
              <p className="flex ml-1 font-bold text-green-600">Ouvert</p>
              <p className="flex ml-1 ">
                • ferme à {timeFloattoString(hours_open[i][i][1])}
              </p>
            </>
          );
        }
        if (heurelocale > hours_open[i][hours_open[today].length - 1][1]) {
          return (
            <>
              <p className="flex ml-1 font-bold text-red-500">Fermé</p>
              <p className="flex ml-1 ">{renderNextOpen()}</p>
            </>
          );
        }
      }
    }
  }

  return open_hours_type === 0 ? (
    <div className="flex flex-col bg-background rounded mx-2 my-2 p-1">
      Pas d'horaires disponibles
    </div>
  ) : open_hours_type === 0 ? (
    <div className="flex flex-col bg-background rounded mx-2 my-2 p-1">
      Pas d'horaires disponibles
    </div>
  ) : (
    <button
      onClick={() => (isClickable ? setclicked(!clicked) : null)}
      className="flex flex-col bg-background rounded mx-2 my-2 "
    >
      <div className="flex mx-1 my-1  items-center">
        <p className="flex ">{renderIsOpen()}</p>
        <div className="flex">
          {isClickable ? (
            clicked ? (
              <ChevronUpIcon className="flex h-5" />
            ) : (
              <ChevronDownIcon className="flex h-5" />
            )
          ) : null}
        </div>
      </div>
      {clicked ? (
        <div className="flex flex-col mx-2">{renderWeekSchedule()}</div>
      ) : null}
    </button>
  );
};

export default OpenHours;
