import React from "react";
import CreatedByDisplay from "./CreatedByDisplay";
import Image from "./Image";
import PageLayout from "./PageLayout";

const BusinessCard = ({ business, gwig }) => {
  return (
    <div className="flex bg-white rounded p-5">
      <div className="flex flex-col ">
        <CreatedByDisplay business={business} className="mb-8" />
        <Image
          src={business.cover_image}
          size="big"
          className="rounded object-cover justify-self-center"
        />
      </div>
    </div>
  );
};

export default BusinessCard;
