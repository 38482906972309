import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import config from "../../config";
import { GwigMapPin } from "../../assets/svg/GwigMapPin";
import useMediaQuery from "@mui/material/useMediaQuery";

var map;
var maps;
var polyline;

const GwigMap = ({
  options = { fullscreenControl: false, gestureHandling: "greedy" },
  dataList = [],
  setHoverIds,
  hoverIds,
  setPinClick,
  setCenter,
  setZoom,
  setBounds,
  center,
  zoom,
}) => {
  const desktopScreen = useMediaQuery("(min-width:768px)");

  const [nearPoints, setNearPoints] = useState([]);
  const [singlePoints, setSinglePoints] = useState([]);

  const deltaLat = 0.0007 / 64;
  const defaultCenter = {
    lat: 48.705,
    lng: -3.828,
  };
  const defaultZoom = 10;
  const mapRef = useRef(null);
  const setCenterAndZoom = () => {
    setZoom(defaultZoom);
    return setCenter(defaultCenter);
  };

  const mapOptions = {
    styles: [
      {
        featureType: "water",
        stylers: [
          {
            saturation: 43,
          },
          {
            lightness: -11,
          },
          {
            hue: "#0088ff",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            hue: "#ff0000",
          },
          {
            saturation: -100,
          },
          {
            lightness: 99,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#808080",
          },
          {
            lightness: 54,
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ece2d9",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ccdca1",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#767676",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#EBE5E0",
          },
        ],
      },
      {
        featureType: "poi.park",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
      {
        featureType: "poi.sports_complex",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
    ],
  };

  const bindMaps = (_map, _maps) => {
    map = _map;
    maps = _maps;

    //polyline
    polyline = new maps.Polyline({
      path: [],
      strokeColor: "#f65e39",
      geodesic: false,
    });
    return polyline.setMap(map);
  };

  useEffect(() => {
    drawPolyline();
  }, [hoverIds]);

  const drawPolyline = () => {
    // Clear polyline

    polyline?.getPath().clear();

    if (hoverIds[1] !== null) {
      const tab = dataList.filter((i) => i._id === hoverIds[1][0])[0];

      for (let i in tab?.path) {
        polyline
          ?.getPath()
          .push(new maps.LatLng(tab.path[i].lat, tab.path[i].long));
      }
    }
  };

  function calculateBarycenter(array = [], axis = "lat") {
    var somme = 0;
    if (array[0].location) {
      array.map((p) => {
        somme += p.location[axis];
      });
    } else {
      array.map((p) => {
        somme += p.path[0][axis];
      });
    }
    return somme / array.length;
  }

  function regroupPoints(points = []) {
    var _id = [];
    var id = [];
    var groups = [];

    points
      .filter((i) => i.length > 1)
      .forEach((_points, i, array) => {
        _id = [];
        _points.forEach((point, i, array) => {
          _id.push(point._id);
          id.push(point._id);
        });
        var lat = calculateBarycenter(_points, "lat");
        var long = calculateBarycenter(_points, "long");
        groups.push({ location: { lat, long }, _id: _id, isGroup: true });
      });

    return { groups, id };
  }

  function regroupByAxis(axis = "lat", points = []) {
    var MEM = [];
    var result = [];
    points.forEach((point, i, array) => {
      if (i === 0) {
        MEM.push(i);
      } else {
        if (array[i]?.location) {
          if (
            Math.abs(
              array[i]?.location[axis] -
                array[MEM[MEM.length - 1]]?.location[axis]
            ) <=
            deltaLat * Math.pow(2, 20 - zoom)
          ) {
            MEM.push(i);
          } else {
            result.push(MEM);

            MEM = [i];
          }
        } else {
          if (
            Math.abs(
              array[i]?.path[0][axis] -
                array[MEM[MEM.length - 1]]?.path[0][axis]
            ) <=
            deltaLat * Math.pow(2, 20 - zoom)
          ) {
            MEM.push(i);
          } else {
            result.push(MEM);

            MEM = [i];
          }
        }
      }
      if (i === points.length - 1) {
        result.push(MEM);
      }
    });

    const nearPoints = result.map((i) => i.map((n) => points[n]));

    return nearPoints;
  }

  useEffect(() => {
    var groupedPoints = [];
    const nearLatPoints = regroupByAxis("lat", dataList);

    nearLatPoints
      .filter((i) => i.length > 1)
      .forEach((points, i, array) => {
        points.sort((a, b) => a.location?.long - b.location?.long);

        var group = regroupByAxis("long", points, true);

        groupedPoints.push(group);
      });

    var { groups, id } = regroupPoints(groupedPoints.flat());

    setSinglePoints(dataList.filter((i) => !id?.includes(i._id)));
    setNearPoints(groups);
  }, [dataList, center, zoom]);

  const renderPointsOnMap = () => {
    const data = singlePoints.concat(...nearPoints);
    return data.map((item, i) => (
      <Marker
        key={i}
        lat={item.location ? item.location?.lat : item.path[0].lat}
        lng={item.location ? item.location?.long : item.path[0].long}
        index={i + 1}
        point={item}
        setHoverIds={setHoverIds}
        hoverIds={hoverIds}
        setPinClick={setPinClick}
      />
    ));
  };

  function handleBoundsChanged(bounds, zoom) {
    var temp = bounds;
    if (desktopScreen) {
      const x = -71.01562500000003; // offset for a zoom = 3
      temp = bounds;
      temp.nw.lng = bounds.nw.lng - x / Math.pow(2, zoom - 3); // each zoom divide offset /2
      temp.sw.lng = bounds.sw.lng - x / Math.pow(2, zoom - 3);
    }
    setBounds(temp);
    setZoom(zoom);
  }

  return (
    <>
      <div className={"fixed inset-0 top-16 z-30  "} ref={mapRef}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: config.MapsApiKey,
          }}
          defaultCenter={defaultCenter}
          center={center}
          defaultZoom={defaultZoom}
          zoom={zoom}
          onChange={({ bounds, zoom, marginBounds }) =>
            handleBoundsChanged(bounds, zoom)
          }
          yesIWantToUseGoogleMapApiInternals
          options={mapOptions}
          onGoogleApiLoaded={({ map, maps }) => {
            setCenterAndZoom();
            bindMaps(map, maps);
          }}
        >
          {renderPointsOnMap()}
        </GoogleMapReact>
      </div>
    </>
  );
};

const Marker = ({
  index,
  point,
  setPinClick,
  setHoverIds,

  hoverIds,
}) => {
  const Pinhoverred = () => {
    if (point.isGroup) {
      setHoverIds([true, point._id]);
    } else {
      setHoverIds([true, [point._id]]);
    }
  };

  const Pinunhoverred = () => {
    setHoverIds([false, null]);
  };

  return (
    <button
      className={`relative -left-5 -top-10 `}
      onClick={() => setPinClick([true, index])}
      onMouseEnter={() => Pinhoverred()}
      onMouseLeave={() => Pinunhoverred()}
    >
      <div
        className={` ${
          hoverIds[0] && point._id.includes(hoverIds[1][0])
            ? "animate-bounce"
            : ""
        }`}
      >
        <GwigMapPin
          color={!point.isGroup ? "#f65e39" : "#7879f1"}
          className="absolute inset-0 "
        ></GwigMapPin>
        <div className="absolute inset-0 top-2 text-md text-white">
          {point.isGroup ? point._id.length : ""}
        </div>
      </div>
    </button>
  );
};

export default GwigMap;
