import React from "react";
import { Link } from "react-router-dom";
import Image from "./Image";
import Text from "./Text";
import { ArrowRightIcon } from "@heroicons/react/outline";

const AttachmentCard = ({ attachment }) => {
  console.log(attachment);

  return (
    <a
      href={attachment.url}
      target="_blank"
      rel="noreferrer"
      className=" flex rounded bg-white w-full md:p-5"
    >
      <Image
        src={attachment.cover_image}
        size="med"
        className="md:w-36 md:h-44 w-16 h-24 object-cover rounded-l "
      />
      <div className="flex flex-col p-2 justify-between w-full">
        <h4 className="md:text-lg overflow-hidden h-5 md:h-10 ">
          {attachment.title}
        </h4>
        <div className="flex flex-col  ">
          <h4 className="text-sm text-conso w-52 md:w-full  overflow-hidden h-5">
            {attachment.url}
          </h4>
          <ArrowRightIcon className="h-5 w-5 md:h-10 md:w-10 text-purple self-end" />
        </div>
      </div>
    </a>
  );
};

export default AttachmentCard;
