import React from "react";
import { Link } from "react-router-dom";
import Image from "./Image";

const CreatedByDisplay = ({
  business,
  showPicture = true,
  nameClassName = "text-text text-md font-bold leading-tight ",
  par = null,
  clickable = true,
}) => {
  const render = (
    <div
      className={
        clickable
          ? " hover:bg-background flex items-center  rounded"
          : "flex items-center  rounded"
      }
    >
      {showPicture ? (
        <Image
          src={business?.profile_picture}
          size="thumb"
          className="h-12 w-12 rounded-full mr-3"
        />
      ) : null}
      <div className="flex flex-col ">
        {showPicture ? (
          <p className={nameClassName}>
            {par}
            {business?.name}
          </p>
        ) : (
          <div className="flex overflow-ellipsis">
            <p className="text-sm text-metal mr-1">par </p>
            <p className={nameClassName + " line-clamp-1 text-left"}>
              {" "}
              {business?.name}
            </p>
          </div>
        )}

        <p className="leading-tight flex  ">
          <h1 className="flex text-conso text-left line-clamp-1">
            {business?.gwigs_pop ? business?.gwigs_pop[0]?.name : ""}
          </h1>
          <span className="mx-1">•</span>
          <span className="flex line-clamp-1 text-left ">
            {business?.activity_label_pop
              ? business?.activity_label_pop?.name
              : business?.activity_category_pop?.name}
          </span>
        </p>
      </div>
    </div>
  );

  return clickable ? (
    <Link
      to={{ pathname: "/business/" + business._id, state: { data: business } }}
    >
      {render}
    </Link>
  ) : (
    render
  );
};

export default CreatedByDisplay;
