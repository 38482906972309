import React, { useState } from "react";

const CookieDisplay = () => {
  const [accepted, setAccepted] = useState(
    Boolean(localStorage.getItem("hasAcceptedCookies"))
  );

  function close() {
    localStorage.setItem("hasAcceptedCookies", true);
    setAccepted(true);
  }

  if (accepted) return null;

  return (
    <div className="fixed bottom-0 w-screen bg-pro p-3">
      <div className="max-w-2xl mx-auto">
        <p className="text-white">
          Nous utilisons des cookies et d'autres technologies pour améliorer
          votre expérience sur nos sites web. En cliquant sur « Accepter tout »,
          vous acceptez que nous utilisions des cookies tiers à des fins
          d'analyse de fonctionnement du site. Pour en savoir plus sur la façon
          dont nous utilisons les cookies, vous pouvez consulter notre{" "}
          <a
            target="_blank"
            href="https://www.gwig.fr/politiques-de-cookies"
            className="underline"
          >
            Politique sur les cookies.
          </a>
        </p>
        <div className="flex gap-3 mt-3">
          <button onClick={close} className="bg-white rounded text-pro w-full">
            Accepter tout
          </button>
          <button onClick={close} className="bg-white rounded text-pro w-full">
            Refuser tout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieDisplay;
