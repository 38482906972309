import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import SubmitButton from "../components/SubmitButton";
import axios from "axios";
import { CheckIcon } from "@heroicons/react/solid";
import { Helmet } from "react-helmet";

const ForgotPasswordPage = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [passwordModified, setPasswordModified] = useState(false);
  const { search } = useLocation();

  const field = "bg-background h-10 rounded text-base px-3 w-full";

  const url = new URLSearchParams(search);
  const token = url.get("token");

  console.log(token);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <Helmet>
        <meta
          name="description"
          content="Gwig est l'application qui regroupe toute la vie municipale, commerciale et associative de votre commune. Evénements, activités, produits... tout le dynamisme local est référencé sur Gwig, l'application citoyenne des communes connectées !"
        />
        <title>Gwig - Mot de passe oublié</title>
      </Helmet>
      <div className="rounded bg-white max-w-3xl p-8 w-full flex flex-col gap-5">
        {token ? (
          <>
            <h1 className="text-xl font-medium">Nouveau mot de passe</h1>
            <Formik
              initialValues={{ password: "", password2: "" }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(8, "Minimum 8 caractères")
                  .required("Requis"),
                password2: Yup.string()
                  .min(8, "Minimum 8 caractères")
                  .required("Requis"),
              })}
              onSubmit={(values) => {
                axios
                  .post("/change-password", {
                    password: values.password,
                    token,
                  })
                  .then((res) => {
                    if (res.data.success) {
                      setPasswordModified(true);
                    }
                  });
              }}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  className="flex flex-col gap-5"
                >
                  <Field
                    name="password"
                    type="password"
                    placeholder="Nouveau mot de passe"
                    className={field}
                  />
                  <ErrorMessage
                    className="text-red-500 text-sm font-medium"
                    component="div"
                    name="password"
                  />
                  <Field
                    type="password"
                    name="password2"
                    placeholder="Confirmez le mot de passe"
                    className={field}
                  />
                  <div className="flex justify-between mt-5 h-12">
                    <Link
                      to="/connexion"
                      className="h-full bg-gray-300 text-background rounded flex items-center justify-center px-5"
                    >
                      Retour
                    </Link>

                    {!passwordModified ? (
                      <SubmitButton
                        text="Envoyer"
                        className="w-32 h-12"
                        disabled={
                          formik.values.password !== formik.values.password2
                        }
                      />
                    ) : (
                      <div className="bg-green bg-opacity-10 text-green rounded flex items-center px-3 underline">
                        <CheckIcon className="h-6 mr-2" />
                        <p>Mot de passe modifié. Retournez à l'application.</p>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <h1 className="text-xl font-medium">Mot de passe oublié</h1>
            <p>
              Saisissez l'addresse email de votre compte. Un email sera envoyé à
              cette adresse et contiendra les instructions vous permettant de
              créer un nouveau mot de passe.
            </p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Email non valide")
                  .required("Requis"),
              })}
              onSubmit={(values) => {
                axios
                  .post("/send-password-recovery-email", {
                    ...values,
                    type: "app",
                  })
                  .then((res) => {
                    if (res.data.success) {
                      setEmailSent(true);
                    }
                  });
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Field
                    name="email"
                    placeholder="adresse@email.com"
                    className={field}
                  />
                  <ErrorMessage
                    className="text-red-500 text-sm font-medium"
                    component="div"
                    name="email"
                  />
                  <div className="flex justify-between mt-5 h-12">
                    <Link
                      to="/connexion"
                      className="h-full bg-gray-300 text-background rounded flex items-center justify-center px-5"
                    >
                      Retour
                    </Link>
                    {!emailSent ? (
                      <SubmitButton text="Envoyer" className="w-32 h-12" />
                    ) : (
                      <div className="bg-green bg-opacity-10 text-green rounded flex items-center px-3">
                        <CheckIcon className="h-6 mr-2" />
                        <p>Un mail vous a été envoyé</p>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
