import React from "react";
import GwigPicker from "./GwigPicker";
import Header from "./Header";
import LoadingLogo from "../components/LoadingLogo";
import SideBar from "../components/SideBar";
import LastActivities from "../components/LastActivities";

const PageLayout = ({ pageTitle, children, loading, back, left, right }) => {
  return (
    <>
      <Header title={pageTitle} back={back} />
      <div className="h-16 w-full " />
      {/* <div className="h-16 w-full mb-4 hidden md:block" /> */}
      {loading ? (
        <div className="flex justify-center h-screen items-center">
          <LoadingLogo />
        </div>
      ) : (
        <>
          <div className="hidden lg:flex fixed top-20 left-5">
            <SideBar />
          </div>
          <div className="hidden lg:flex  fixed top-20 right-5 ">
            <LastActivities />
          </div>
          <div className="flex mx-auto max-w-lg  sm:px-0 pb-14">{children}</div>
        </>
      )}
      {/* <div className="hidden md:block" />
      <div className="hidden md:block">{left}</div>
      <div className="hidden md:block" /> */}

      {/* <div className="hidden md:block" />
      <div className="hidden"></div>
      <div className="hidden" /> */}
    </>
  );
};

export default PageLayout;
