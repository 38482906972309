import React, { useState, useEffect } from "react";
import moment from "moment";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import GwigMapEventCard from "../../components/mapComponents/GwigMapEventCard";
import axios from "axios";
import LoadingLogo from "../../components/LoadingLogo";
import MenuHeader from "../../components/mapComponents/MenuHeader";

const EventMenu = ({
  setCenter,
  setZoom,
  hoverIds,
  setHoverIds,
  setDataList,
  bounds,
  dataList,
}) => {
  const [selectedDay, setSelectedDay] = useState(0); // day selected to render event
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (bounds?.nw?.lat) {
      axios
        .post("/open/list/event", {
          params: {
            filter: {
              isActivity: false,
              $or: [
                {
                  isRepeating: false,
                  datetime_out: {
                    $gte: moment()
                      .hour(0)
                      .minute(1)
                      .add(selectedDay, "days")
                      .format(),
                  },
                  datetime_in: {
                    $lte: moment()
                      .hour(23)
                      .minute(59)
                      .add(selectedDay, "days")
                      .format(),
                  },
                },
                {
                  isRepeating: true,
                  repeats_on:
                    moment().hour(0).minute(0).add(selectedDay, "days").day() -
                    1,
                  datetime_out: {
                    $gte: moment()
                      .hour(0)
                      .minute(0)
                      .add(selectedDay, "days")
                      .format(),
                  },
                  datetime_in: {
                    $lte: moment()
                      .hour(0)
                      .minute(0)
                      .add(selectedDay, "days")
                      .format(),
                  },
                },
              ],

              "location.lat": {
                $lte: bounds.ne.lat,
                $gte: bounds.se.lat,
              },
              "location.long": {
                $lte: bounds.ne.lng,
                $gte: bounds.nw.lng,
              },
            },
            sort: "location.lat",
            populate: "createdBy",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setDataList(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [bounds, selectedDay]);

  function renderDaySelector() {
    return (
      <>
        <MenuHeader
          loading={loading}
          setDataList={setDataList}
          title="Agenda géolocalisé"
        />

        <div className="flex bg-background  h-11  justify-between   w-full">
          <button
            className={"flex w-14 justify-center"}
            onClick={() => setSelectedDay(selectedDay - 1)}
          >
            <ChevronLeftIcon className="h-7   self-center" />
          </button>

          <div className="flex mx-3">
            <p className=" ml-1 font-bold self-center">
              {moment()
                .hour(0)
                .minute(0)
                .add(selectedDay, "days")
                .format("dddd")}{" "}
              {moment()
                .hour(0)
                .minute(0)
                .add(selectedDay, "days")
                .format("DD MMMM")}
            </p>
          </div>

          <button
            className={"flex w-14 justify-center"}
            onClick={() => setSelectedDay(selectedDay + 1)}
          >
            <ChevronRightIcon className="h-7   self-center" />
          </button>
        </div>
      </>
    );
  }

  function renderEvents() {
    if (dataList.length) {
      return dataList.map((event, i) => {
        return (
          <>
            <GwigMapEventCard
              key={i}
              event={event}
              nb={i}
              setCenter={setCenter}
              setZoom={setZoom}
              hoverIds={hoverIds}
              setHoverIds={setHoverIds}
            />
          </>
        );
      });
    }
    return (
      <>
        <p>aucun événement pour le jour selectionné</p>
      </>
    );
  }

  return (
    <>
      <div>{renderDaySelector()}</div>
      <div
        className="flex-col mb-3 px-2 overflow-y-auto overflow-x-hidden"
        style={{ height: "calc(100% - 112px)" }}
      >
        {renderEvents()}
      </div>
    </>
  );
};

export default EventMenu;
