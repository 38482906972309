import { BikeParkingIcon, DefibrillatorIcon, ElectricPointIcon, ParkIcon, ParkingIcon, PicnicTableIcon, ToiletsIcon } from "../assets/svg/UtilitaryPointIcons";

const equipementTypesList = [
    {
        name: "Parkings",
        value: 'parking',
        icon: <ParkingIcon />,
        bg: "#2223C6"
    },
    {
        name: "Parking à vélos",
        value: "bike_parking",
        icon: <BikeParkingIcon />,
        bg:"#7879F1"
    },
    {
        name: "Toilettes publiques",
        value: "toilet",
        icon: <ToiletsIcon />,
        bg:"#8190B0"
    },
    {
        name: "Parcs et jardin",
        value: "park",
        icon: <ParkIcon />,
        bg:"#49CC90"
    },
    {
        name: "Tables picnic",
        value: "picnic_table",
        icon: <PicnicTableIcon />,
         bg:"#FCB96A"
    },
    {
        name: "Bornes de recharge",
        value: "recharge_point",
        icon: <ElectricPointIcon />,
        bg:"#1DCDC3"
    },
    {
        name: "Défibrillateurs",
        value: "defibrillator",
        icon: <DefibrillatorIcon />,
        bg:"#F61717"
    },
];

export default equipementTypesList;