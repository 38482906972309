import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import EventCard from "../components/EventCard";
import LongEventCard from "../components/LongEventCard";
import { useSelector } from "react-redux";
import MairieBanner from "../components/MairieBanner";
import moment from "moment";
import LoadingLogo from "../components/LoadingLogo";
import GwigSelector from "../components/GwigSelector";
import { Helmet } from "react-helmet";

const AgendaPage = () => {
  window.scrollTo(0, 0);
  const { selectedMairie, selectedGwigs } = useSelector((state) => state);

  const { data: events, loading } = useGetData({
    url: "/open/get-events",
    params: {
      params: {
        filter: {
          gwigs: {
            $in: [...selectedMairie.gwigs, ...selectedGwigs.map((i) => i._id)],
          },
        },
        populate: "createdBy",
      },
    },
  });

  function renderEvents() {
    return events.map((event, i) => {
      const longEvents = event.data.filter((i) => i.isLong);
      const regularEvents = event.data.filter((i) => !i.isLong);

      return (
        <>
          {/* DESKTOP DAY SEPARATOR */}
          <div className="hidden md:flex bg-purple rounded h-11 my-3 justify-center mb-5 mt-12 w-full">
            <p className="text-white text-md font-bold self-center capitalize">
              {(moment(event.title).isSame(moment(), "day")
                ? "Aujourd'hui "
                : "") + moment(event.title).format("dddd")}
            </p>
            <p className="text-white text-md ml-1 font-bold self-center">
              {moment(event.title).format("DD MMMM")}
            </p>
          </div>
          {/* MOBILE DAY SEPARATOR */}
          <div className="md:hidden bg-purple rounded-l w-40 rounded-full justify-center  mb-3 mt-8 py-2 pr-3 pl-3">
            <p className="text-white text-md font-bold self-center capitalize">
              {moment(event.title).format("dddd DD ")}
            </p>
            <p className="text-white text-md ml-1 font-bold self-center">
              {moment(event.title).format("MMMM")}
            </p>
          </div>
          <div key={i} className="flex flex-col p-3 sm:p-0">
            {!regularEvents.length && !longEvents.length ? (
              <div className="my-8 italic">
                Pas d'événements prévus aujourd'hui
              </div>
            ) : null}
            {regularEvents.length
              ? regularEvents.map((e, i) => (
                  <Link
                    to={{
                      pathname: "/evenement/" + e._id,
                      state: { day: event.title },
                    }}
                    key={i}
                    className="mb-3"
                  >
                    <EventCard event={e} gwig={selectedMairie} />
                  </Link>
                ))
              : null}
            {longEvents.length ? (
              <>
                <h2 className="font-bold text-metal my-5 ">
                  Evénements sur plusieurs jours
                </h2>
                <div className="mb-8 flex flex-col sm:grid sm:grid-cols-2 gap-3">
                  {longEvents.map((e, i) => (
                    <Link
                      to={{
                        pathname: "/evenement/" + e._id,
                        state: { day: event.title },
                      }}
                      key={i}
                    >
                      <LongEventCard event={e} gwig={selectedMairie} />
                    </Link>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </>
      );
    });
  }

  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez tous les événements et activités de la ${selectedMairie.name} sur l'agenda collaboratif Gwig. Marchés, balades, activités sportives... découvrez que faire à ${selectedMairie.gwigs_pop[0].name} dans les prochains jours !`}
        />
        <title>
          Gwig -
          {selectedGwigs.length >= 1
            ? " Évènements"
            : " Évènements à " + selectedMairie.gwigs_pop[0].name}
        </title>
      </Helmet>
      <div className=" flex flex-col w-full">
        <MairieBanner mairie={selectedMairie} variant="change" />
        <GwigSelector />
        <div className="hidden md:flex font-bold text-md my-4">
          Agenda des événements
        </div>
        {loading ? <LoadingLogo className="mt-5 mb-96" /> : renderEvents()}
      </div>
    </PageLayout>
  );
};

export default AgendaPage;
