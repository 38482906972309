import React, { useState } from "react";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import { useSelector } from "react-redux";
import GwigSelector from "../components/GwigSelector";
import MairieBanner from "../components/MairieBanner";
import PointMap from "../components/PointMap";
import equipementTypesList from "../services/equipementTypesList";
import { Helmet } from "react-helmet";

const PointsPage = () => {
  const { selectedMairie, selectedGwigs } = useSelector((state) => state);
  const [category, setCategory] = useState("");

  const { loading: loadingParents, data: parents } = useGetData({
    url: "/open/list/point",
    params: {
      params: {
        filter: {
          type: "utilitary",
          gwigs: {
            $in: [...selectedMairie.gwigs, ...selectedGwigs.map((i) => i._id)],
          },
          isChild: false,
        },
        populate: "children",
      },
    },
  });

  const { loading: loadingChildren, data: children } = useGetData({
    url: "/open/list/point",
    params: {
      params: {
        filter: {
          type: "utilitary",
          utilitary_type: !category ? { $ne: "" } : category,
          gwigs: {
            $in: [...selectedMairie.gwigs, ...selectedGwigs.map((i) => i._id)],
          },
          isChild: true,
        },
      },
    },
    dependencies: [category],
  });

  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content="Gwig est l'application qui regroupe toute la vie municipale, commerciale et associative de votre commune. Evénements, activités, produits... tout le dynamisme local est référencé sur Gwig, l'application citoyenne des communes connectées !"
        />
        <title>
          Gwig -
          {" Equipements et Mobilier urbains de " +
            selectedMairie.gwigs_pop[0].name}
        </title>
      </Helmet>
      <div className="flex flex-col w-full">
        <MairieBanner
          mairie={selectedMairie}
          commune={selectedMairie.gwigs_pop[0].slug}
          variant={"change"}
        />
        <GwigSelector />
        <div className="flex font-bold text-md my-5">
          Equipements et Mobilier urbains
        </div>
        {parents.length ? (
          <>
            <div className="flex p-2 gap-3">
              {loadingParents ? (
                <p>Chargement...</p>
              ) : (
                <>
                  <button
                    className="bg-white p-2 rounded hover:bg-opacity-60"
                    onClick={() => setCategory("")}
                  >
                    Tout
                  </button>
                  {parents.map((item, i) => {
                    const type = equipementTypesList.find(
                      (e) => e.value === item.utilitary_type
                    );
                    return (
                      <button
                        className={
                          "flex items-center bg-white p-2 rounded hover:bg-opacity-60 gap-2  " +
                          (item.utilitary_type === category
                            ? "bg-metal text-white "
                            : "")
                        }
                        key={i}
                        onClick={() => setCategory(item.utilitary_type)}
                      >
                        {type.icon}
                        {item.name}
                      </button>
                    );
                  })}
                </>
              )}
            </div>
            <PointMap
              points={children}
              className="h-96 rounded overflow-hidden relative"
            />
          </>
        ) : (
          <div>aucun équipement...</div>
        )}
      </div>
    </PageLayout>
  );
};

export default PointsPage;
