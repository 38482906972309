import React from "react";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import MairieBanner from "../components/MairieBanner";
import PostCard from "../components/PostCard";
import { useSelector } from "react-redux";
import LoadingLogo from "../components/LoadingLogo";
import GwigSelector from "../components/GwigSelector";
import { Helmet } from "react-helmet";

const FeedPage = () => {
  const { selectedMairie, selectedGwigs } = useSelector((state) => state);

  const { data: posts, loading } = useGetData({
    url: "/open/list/post",
    params: {
      params: {
        filter: {
          gwigs: {
            $in: [...selectedMairie.gwigs, ...selectedGwigs.map((i) => i._id)],
          },
        },
        populate: "createdBy attachments.item",
        sort: "-publishDate",
        limit: 25,
      },
    },
    dependencies: [],
  });

  function renderPosts() {
    return posts.map((post, i) => (
      <PostCard
        post={post}
        gwig={selectedMairie}
        commune={selectedMairie.gwigs_pop[0].slug}
        size="big"
      />
    ));
  }

  // function renderPosts() {
  //   return posts.map((post, i) => (

  //     <PostCard post={post} gwig={selectedMairie} commune={selectedMairie.gwigs_pop[0].slug} size="big"/>
  //   ));
  // }

  console.log(posts);
  return (
    <PageLayout
      loading={false}
      // left={<div className="text-center">left</div>}
      // right={<div className="text-center">right</div>}
    >
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez toutes les associations et commerces de la ${selectedMairie.name} sur l'annuaire Gwig!`}
        />
        <title>
          Gwig -{" Dernières actualités " + selectedMairie.gwigs_pop[0].name}
        </title>
      </Helmet>
      <div className=" flex flex-col w-full">
        <MairieBanner mairie={selectedMairie} variant="change" />
        <GwigSelector />
        <div className="flex font-bold text-md my-5 w-full">
          Dernières actualités
        </div>
        <div className="flex flex-col gap-2 sm:gap-5 ">
          {loading ? <LoadingLogo /> : renderPosts()}
        </div>
      </div>
    </PageLayout>
  );
};

export default FeedPage;
