import React from "react";
import { useSelector } from "react-redux";
import useGetData from "../hooks/useGetData";
import LoadingLogo from "./LoadingLogo";
import moment from "moment";
import { Link } from "react-router-dom";

const LastActivities = () => {
  // const { selectedMairie, selectedGwigs } = useSelector((state) => state);

  // const { data: posts, loading } = useGetData({
  //   url: "/open/list/post",
  //   params: {
  //     params: {
  //       filter: {
  //         gwigs: {
  //           $in: [...selectedMairie.gwigs, ...selectedGwigs.map((i) => i._id)],
  //         },
  //       },
  //       populate: "createdBy attachments.item",
  //       sort: "-publishDate",
  //       limit: 5,
  //     },
  //   },
  //   dependencies: [],
  // });

  // function renderPosts(post, i) {
  //   console.log(post);
  //   var link = "";
  //   if (post.attachments?.length > 0) {
  //     switch (post.attachments[0].ref) {
  //       case "Product":
  //         link = {
  //           pathname: "/business/" + post.createdBy._id,
  //           state: { data: post.createdBy },
  //         };
  //         break;
  //       case "Event":
  //         link = "/evenement/" + post.attachments[0].item._id;
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  //   return (
  //     <div className="flex-col bg-white rounded mb-3 p-3 w-80" key={i}>
  //       <div className="flex w-full ">{post.title}</div>
  //       <div className="flex">
  //         <div className="text-sm  text-metal mr-1">par</div>
  //         <div className="text-sm  text-conso font-bold">
  //           {post.createdBy.name}
  //         </div>
  //       </div>
  //       <div className="flex items-center justify-between ">
  //         <p className="text-sm  text-metal ">
  //           {moment(post.publishDate).fromNow()}
  //         </p>
  //         {post.attachments?.length > 0 ? (
  //           <Link
  //             to={link}
  //             className="text-sm bg-background px-2 py-1 rounded text-metal"
  //           >
  //             voir
  //           </Link>
  //         ) : null}
  //       </div>
  //     </div>
  //   );
  // }

  // if (selectedMairie.name) {
  //   return (
  //     <div className="hidden md:flex flex-col ">
  //       <div className="flex text-purple text-md font-marker mb-5">
  //         Toutes les dernières actualités
  //       </div>
  //       {loading ? (
  //         <LoadingLogo />
  //       ) : (
  //         posts.map((post, i) => renderPosts(post, i))
  //       )}
  //     </div>
  //   );
  // }
  return null;
};

export default LastActivities;
