import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import config from "../config";
import { ArrowsExpandIcon, TranslateIcon } from "@heroicons/react/outline";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import equipementTypesList from "../services/equipementTypesList";
import { PinIcon } from "../assets/svg/PinIcon";
// import "./map.scss";

var map;
var maps;
var polyline;

const PointMap = ({
  points,
  className = "w-full h-full ",
  options = { fullscreenControl: false, gestureHandling: "greedy" },
}) => {
  const defaultCenter = {
    lat: 48.705,
    lng: -3.828,
  };
  const defaultZoom = 13;
  const mapRef = useRef(null);
  // Set default center to user address coords or default to Plougasnou
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  const [mapFullScreen, setMapFullScreen] = useState(false);

  // Bind maps' objects to global
  const bindMaps = (_map, _maps) => {
    map = _map;
    maps = _maps;
  };

  useEffect(() => {
    setCenterAndZoom();
  }, [points]);

  const setCenterAndZoom = () => {
    if (!points.length) {
      setZoom(defaultZoom);
      return setCenter(defaultCenter);
    }
    const bounds = {
      nw: {
        lat: Math.max(...points?.map((i) => i.location?.lat)),
        lng: Math.min(...points?.map((i) => i.location?.long)),
      },
      se: {
        lat: Math.min(...points?.map((i) => i.location?.lat)),
        lng: Math.max(...points?.map((i) => i.location?.long)),
      },
    };

    const size = {
      width: mapRef.current.offsetWidth,
      height: mapRef.current.offsetHeight,
    };

    if (points.length > 1) {
      const { center, zoom } = fitBounds(bounds, size);
      setCenter(center);
      setZoom(zoom);
    } else {
      setCenter({
        lat: points[0].location?.lat,
        lng: points[0].location?.long,
      });
      setZoom(13);
    }
  };

  const renderPointsOnMap = () => {
    return points?.map((item, i) => (
      <Marker
        key={i}
        lat={item.location?.lat}
        lng={item.location?.long}
        point={item}
      />
    ));
  };

  return (
    <div
      className={mapFullScreen ? "fixed inset-0 z-30  " : className}
      ref={mapRef}
    >
      {mapFullScreen ? (
        <div className="fixed top-5 left-5 z-50 flex justify-between right-5">
          <div />
          <button
            type="button"
            onClick={() => setMapFullScreen(false)}
            className="rounded bg-white px-3 h-12 font-medium text-pro border-pro border-2"
          >
            Fermer le plein écran
          </button>
        </div>
      ) : // <button
      //   className="rounded bg-white h-12 w-12 font-medium text-pro border-pro border-2 absolute z-30 top-3 right-3 flex items-center justify-center"
      //   onClick={() => setMapFullScreen(true)}
      //   type="button"
      // >
      //   <ArrowsExpandIcon className="w-6" />
      // </button>
      null}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.MapsApiKey,
        }}
        defaultCenter={defaultCenter}
        center={center}
        defaultZoom={defaultZoom}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={options}
        onGoogleApiLoaded={({ map, maps }) => {
          // Make those available globally
          bindMaps(map, maps);
          return setCenterAndZoom();
        }}
      >
        {renderPointsOnMap()}
      </GoogleMapReact>
    </div>
  );
};

const Marker = ({ point }) => {
  const type = equipementTypesList.find(
    (e) => e.value === point.utilitary_type
  );
  const [show, setShow] = useState(false);
  return (
    <button
      style={{ right: 15, bottom: 30 }}
      className="relative "
      onClick={() => setShow(!show)}
    >
      <PinIcon color={type.bg} className="absolute inset-0"></PinIcon>
      <div className="absolute left-1 top-1 text-white">{type.icon}</div>
      {show ? (
        <div className="p-2 rounded bg-white ml-2 text-sm ">{point.name}</div>
      ) : null}
    </button>
  );
};

export default PointMap;
