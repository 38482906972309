import CircuitMap from "./CircuitMap";
import PointCard from "./PointCard";

const CircuitPanel = ({ data }) => {
  let stopropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="flex md:flex-row flex-col bg-background md:rounded w-full h-full"
      onClick={stopropagation}
    >
      <div className="md:w-2/5 md:h-full h-2/5">
        <CircuitMap circuit={data} />
      </div>
      <div className="flex flex-col  md:p-10 p-3 md:w-3/5 md:h-full h-3/5 gap-5 ">
        <p className="text-center font-bold text-lg">{data.name}</p>
        <div className="flex flex-col overflow-y-auto gap-5 md:mb-0 mb-14">
          <p>{data.description}</p>
          {data.points.length
            ? data.points.map((p) => <PointCard point={p} />)
            : null}
        </div>
      </div>
    </div>
  );
};

export default CircuitPanel;
