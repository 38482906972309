import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const ReturnMenuBouton = ({ setDataList }) => {
  return (
    <Link
      to={`/carte`}
      onClick={() => setDataList([])}
      className="text-background flex items-center"
    >
      <ArrowLeftIcon className="h-5 mx-2" />
      retour
    </Link>
  );
};

export default ReturnMenuBouton;
