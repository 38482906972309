import { combineReducers } from 'redux';

import userReducer from './userReducer';
import selectedGwigsReducer from './selectedGwigsReducer';
import selectedMairieReducer from './selectedMairieReducer';
// import businessReducer from './businessReducer';
// import sidebarReducer from './sidebarReducer';

const rootReducer = combineReducers({
    user: userReducer,
    selectedGwigs: selectedGwigsReducer,
    selectedMairie: selectedMairieReducer
    // business: businessReducer,
    // sidebar: sidebarReducer
})

export default rootReducer;