const initial = JSON.parse(localStorage.getItem('selectedMairie')) || {};

const selectedMairieReducer = (state = initial, { type, payload }) => {
    switch (type) {
        case "SET_MAIRIE_SELECTION":
            return payload;
        case "RESET_MAIRIE_SELECTION":
            return {};
        default:
            return state;
    }
}

export default selectedMairieReducer;