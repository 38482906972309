import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import { Helmet } from "react-helmet";
import Image404 from "../assets/images/404.png";

const Page404 = () => {
  let history = useHistory();

  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content="Gwig est l'application qui regroupe toute la vie municipale, commerciale et associative de votre commune. Evénements, activités, produits... tout le dynamisme local est référencé sur Gwig, l'application citoyenne des communes connectées !"
        />
        <title>Gwig - Erreur 404</title>
      </Helmet>
      <div className={"flex-col items-center justify-center w-full"}>
        <img src={Image404} className=" mt-10 rounded-md" alt="404" />
      </div>
    </PageLayout>
  );
};

export default Page404;
