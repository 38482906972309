import React, { useState } from "react";
import moment from "moment";
import { GwigMapPin } from "../../assets/svg/GwigMapPin";
import CreatedByDisplay from "../CreatedByDisplay";
import Marker from "./Marker";

const GwigMapEventCard = ({
  event,
  nb,
  setCenter,
  setZoom,
  hoverIds,
  setHoverIds,
}) => {
  const setCenterAndZoom = () => {
    setCenter({
      lat: event.location.lat,
      lng: event.location.long,
    });
  };

  return (
    <>
      <button
        onClick={() => setCenterAndZoom()}
        onMouseEnter={() => {
          if (event.isGroup) {
            setHoverIds([true, event._id]);
          } else {
            setHoverIds([true, [event._id]]);
          }
        }}
        onMouseLeave={() => setHoverIds([false, null])}
        className={
          hoverIds[0] === true && hoverIds[1] === event._id
            ? " flex mb-4 bg-background rounded w-full"
            : "flex mb-4 hover:bg-background rounded  w-full"
        }
      >
        <div
          className={` ${
            hoverIds[0] && hoverIds[1].includes(event._id)
              ? "animate-bounce"
              : ""
          }`}
        >
          <Marker key={nb} />
        </div>
        <div className="flex-col">
          <p className="text-left font-bold line-clamp-1 w-80">{event.title}</p>

          <CreatedByDisplay
            business={event.createdBy}
            showPicture={false}
            nameClassName={"text-sm"}
            clickable={false}
          />
          <p className="flex mt-2">
            <div className="flex rounded text-metal bg-metal bg-opacity-20 ">
              {moment(event.datetime_in).format("DD MMM") ===
              moment(event.datetime_out).format("DD MMM") ? (
                <p className="text-base mx-4">
                  {"Le "}
                  {moment(event.datetime_in).format("DD MMM")}
                </p>
              ) : (
                <p className="text-base mx-4">
                  {"Du "}
                  {moment(event.datetime_in).format("DD MMM")}
                  {" au "}
                  {moment(event.datetime_out).format("DD MMM")}
                </p>
              )}
            </div>
            <div className="flex rounded text-purple bg-purple bg-opacity-20 ml-2">
              <p className="text-base mx-4">
                {"De "}
                {moment(event.datetime_in).format("HH")}
                {"h"}
                {moment(event.datetime_in).format("mm") === "00"
                  ? null
                  : moment(event.datetime_in).format("mm")}
                {" à "}
                {moment(event.datetime_out).format("HH")}
                {"h"}
                {moment(event.datetime_out).format("mm") === "00"
                  ? null
                  : moment(event.datetime_out).format("mm")}
              </p>
            </div>
          </p>
        </div>
      </button>
    </>
  );
};

export default GwigMapEventCard;
