export const IconDistance = () => {
  return (
    <svg
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1163 1L21 5.99998M21 5.99998L16.1163 11M21 5.99998H1M1 5.99998L5.88372 11M1 5.99998L5.88372 1.00004"
        stroke="#F65E39"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
