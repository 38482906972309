import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import { ArrowLeftIcon, MenuIcon } from "@heroicons/react/outline";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetMairieSelection } from "../actions/selectedMairieActions";
import { useSelector } from "react-redux";

const Header = ({ title = "Commune", back }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedMairie = useSelector((state) => state.selectedMairie);

  // close menu when change
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <div className="bg-white h-16 w-full flex items-center z-50 fixed shadow-lg">
      <div className="flex px-4 items-center justify-between w-full md:max-w-7xl md:w-full mx-5">
        <div className="flex items-center ">
          {back ? (
            <div onClick={() => history.goBack()} className="cursor-pointer">
              <ArrowLeftIcon className="h-6 w-6 mr-3" />
            </div>
          ) : (
            <button
              onClick={() => {
                dispatch(resetMairieSelection());
                history.push({
                  pathname: "/",
                });
              }}
            >
              <img src={Logo} className="h-10 w-10 mr-3" alt="logo de Gwig" />
            </button>
          )}
          <h1 className="font-marker md:flex hidden">{title}</h1>
        </div>
        <div className="flex">
          <NavLink
            className="px-3 py-2 mx-3 rounded"
            to={
              selectedMairie?.gwigs_pop
                ? "/commune/" + selectedMairie.gwigs_pop[0].slug
                : "/communes"
            }
            activeClassName="text-white bg-pro"
          >
            <div className="flex items-center text-center font-marker">
              Vue commune
            </div>
          </NavLink>
          <NavLink
            className="relative px-3 py-2 mx-3 rounded"
            to={"/carte"}
            activeClassName="text-white bg-pro"
          >
            <div className="flex items-center text-center font-marker ">
              Vue carte
            </div>
            <div className="absolute -right-12 -top-2 bg-accent-logo  text-white items-center text-center text-xss p-1 rounded ">
              En développement
            </div>
          </NavLink>
        </div>
        <div></div>

        {/* <div className="relative md:hidden">
          <button
            className="flex items-center bg-background rounded p-2"
            onClick={() => setOpen(!open)}
          >
            <MenuIcon className="h-6 w-6" />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
