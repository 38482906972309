import CommunePage from '../pages/CommunePage';
import AgendaPage from '../pages/AgendaPage';
import FeedPage from '../pages/FeedPage';
import PostPage from '../pages/PostPage';
import EventPage from '../pages/EventPage';
import GwigPickerPage from '../pages/GwigPickerPage';
import AnnuairePage from '../pages/AnnuairePage';
import PatrimoinePage from '../pages/PatrimoinePage';
import BusinessPage from '../pages/BusinessPage'
import PasswordRecoveryPage from '../pages/PasswordRecoveryPage';
import ItemModalPage from '../pages/ItemModalPage';
import PointsPage from '../pages/PointsPage';
import DemarchesPage from '../pages/DemarchesPage';
import DemarchePage from '../pages/DemarchePage';
import MapPage from '../pages/mapPages/MapPage';
import Page404 from '../pages/Page404';

const routes = [
    {
        path: '/',
        name: "gwig picker",
        component: GwigPickerPage,
        exact: true
    },
    {
        path: '/carte',
        name: "Map",
        component: MapPage,
    },
    {
        path: '/commune/:gwigSlug',
        name: "Ma commune",
        component: CommunePage,
        exact: true
    },
    {
        path: '/evenements/:gwigSlug',
        name: "Agenda",
        component: AgendaPage,
    },
    {
        path: '/evenement/:id',
        name: "Evenement",
        component: EventPage,
    },
    {
        path: '/posts/:gwigSlug',
        name: "Posts",
        component: FeedPage,
    },
    {
        path: '/post/:id',
        name: "Post",
        component: PostPage,
    },
    {
        path: "/annuaire/:gwigSlug",
        name: "Annuaire",
        component: AnnuairePage,
    },
    {
        path: "/patrimoine/:gwigSlug",
        name: "Patrimoine",
        component: PatrimoinePage,
    },
    {
        path: "/business/:id",
        name: "Business",
        component: BusinessPage,
    },
    {
        path: "/view/:item/:id",
        name: "Business",
        component: ItemModalPage,
    },
    {
        path: "/points/:gwigSlug",
        name: "Points",
        component: PointsPage,
    },
    {
        path: "/demarches/:gwigSlug",
        name: "Demarches",
        component: DemarchesPage,
    },
    {
        path: '/demarche/:id',
        name: "Demarche",
        component: DemarchePage,
    },
    {
        path: "/forgot-password",
        name: "Récupération de password",
        component: PasswordRecoveryPage
    },   
    {
        path: "*",
        name: "Erreur 404",
        component: Page404
    }, 
]

export default routes;