import React from "react";

export const PinIcon = ({ color }) => {
  return (
    <svg
      width="26"
      height="31"
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.80785 3.76093C6.24579 1.35285 9.55236 0 13.0001 0C16.4479 0 19.7545 1.35285 22.1924 3.76093C24.6304 6.16901 26 9.43508 26 12.8406C26 16.2462 24.6304 19.5122 22.1924 21.9203L13.0001 31L3.80785 21.9203C2.60062 20.728 1.64299 19.3125 0.989632 17.7546C0.336278 16.1967 0 14.5269 0 12.8406C0 11.1543 0.336278 9.48458 0.989632 7.92668C1.64299 6.36877 2.60062 4.95325 3.80785 3.76093Z"
        fill={color}
      />
    </svg>
  );
};
