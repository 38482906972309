import React from "react";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import PostCard from "../components/PostCard";
import LoadingLogo from "../components/LoadingLogo";
import BackButton from "../components/BackButton";

const PostPage = () => {
  window.scrollTo(0, 0);
  const { id } = useParams();
  const selectedMairie = useSelector((state) => state.selectedMairie);

  const { data: post = {}, loading } = useGetData({
    url: "/open/read/post/" + id,
    params: {
      params: {
        populate: "gwigs createdBy",
      },
    },
  });

  if (!post) {
    return (
      <PageLayout>
        <p>Aucun élément trouvé :(</p>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className=" flex flex-col w-full">
        <BackButton />
        {loading ? (
          <LoadingLogo />
        ) : (
          <PostCard
            post={post}
            gwig={selectedMairie}
            commune={selectedMairie.gwigs_pop[0].slug}
            cardsize="simple"
          />
        )}
      </div>
    </PageLayout>
  );
};

export default PostPage;
