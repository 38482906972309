import React, { useState, useEffect } from 'react';
import LoadingLogo from './components/LoadingLogo';
import moment from 'moment';
import "moment/locale/fr";
import { BrowserRouter as Router, Redirect, useParams, Switch, Route, useLocation, useHistory } from 'react-router-dom';
import publicRoutes from './routes/publicRoutes';
import axios from 'axios';
import CookieDisplay from './components/CookieDisplay';
import { updateMairieSelection } from './actions/selectedMairieActions';
import { useDispatch } from 'react-redux';
import ItemModalPage from './pages/ItemModalPage';

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();


  useEffect(() => {
    moment.locale("fr"); // Set correct timezone for moment


    if (process.env.REACT_APP_ENVI === 'production') {
      console.log('setting axios base url to prod')
      axios.defaults.baseURL = "https://api.gwig.fr";
    } else {
      // axios.defaults.baseURL = "http://localhost:8889";
      axios.defaults.baseURL = "https://api.gwig.fr";//https://gwig-api-dev.herokuapp.com/
    }


    setLoading(false);
  }, [])

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-background">
        <LoadingLogo />
      </div>
    );
  }

  return (
    <div className="bg-background min-h-screen">
      <PagesRouterStructure />
      <MapRouterStructure />
      <CookieDisplay />
    </div>
  );
}

const PagesRouterStructure = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  //const {gwigSlug} = useParams();
  let background = location.state?.background;

  useEffect(() => {
    // récupérer le slug /posts/plougasnou
    const gwigSlug = location.pathname.split("/")[2];

    console.log(gwigSlug); // "", "posts", "plougasnou"

    // récupérer la mairie
    axios.get('/open/getMairieFromGwigSlug/' + gwigSlug).then(res => {
      if (res.data.success) {
        // mettre dans redux
        dispatch(updateMairieSelection({ mairie: res.data.data }));
        setLoading(false)
      }
    }).catch(err => {
      setLoading(false)
      //<Redirect to ="/"/>
      console.log(err)
    })

  }, [])

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-background">
        <LoadingLogo />
      </div>
    );
  }

  return (
    <>
      <Switch location={background || location}>
        {publicRoutes.map(route => <Route key={route.name} {...route} />)}
      </Switch>
      {background && <Route path="/view/:type/:id" children={<ItemModalPage />} />}
    </>
  )
}

const MapRouterStructure = () => {
  return null
}

export default App;
