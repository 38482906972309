export const updateGwigsSelection = ({ selection, onSuccess = function () { } }) => async (dispatch) => {
    // TODO save
    // localStorage.setItem('selectedGwigs', JSON.stringify(selection))

    dispatch({
        type: "SET_GWIG_SELECTION",
        payload: selection
    })

    onSuccess();

}