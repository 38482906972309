import { GwigMapPin } from "../../assets/svg/GwigMapPin";

const Marker = () => {
  return (
    <div className="relative self-center mr-2">
      <GwigMapPin color="#f65e39" className="absolute inset-0"></GwigMapPin>
      <div className="absolute inset-x-0 top-2 text-md text-center text-white"></div>
    </div>
  );
};

export default Marker;
