import React, { useState } from "react";
import MairieBanner from "../components/MairieBanner";
import PageLayout from "../components/PageLayout";
import { useSelector } from "react-redux";
import useGetData from "../hooks/useGetData";
import GwigSelector from "../components/GwigSelector";
import LoadingLogo from "../components/LoadingLogo";
import { Link } from "react-router-dom";
import CircuitCard from "../components/CircuitCard";
import { useLocation } from "react-router";
import PointCard from "../components/PointCard";
import { Helmet } from "react-helmet";

const PatrimoinePage = () => {
  window.scrollTo(0, 0);
  const { selectedMairie, selectedGwigs } = useSelector((state) => state);
  const location = useLocation();
  const [showPoint, setShowPoints] = useState(false);

  const { data: circuitList, loading } = useGetData({
    url: "/open/list/circuit",
    params: {
      params: {
        filter: {
          gwigs: {
            $in: [...selectedMairie.gwigs, ...selectedGwigs.map((i) => i._id)],
          },
        },
        sort: "-publishDate",
        populate: "points",
      },
    },
    dependencies: [],
  });

  function renderCircuits() {
    return circuitList.map((circuit, i) => (
      <Link
        key={i}
        to={{
          pathname: "/view/circuit/" + circuit._id,
          state: { background: location, data: circuit },
        }}
        className="relative  gap-1 w-full"
      >
        <CircuitCard circuit={circuit} />
      </Link>
    ));
  }

  function renderPoints() {
    if (!showPoint) {
      circuitList.map((c) =>
        c.points
          .filter((p) => p.isVisible)
          .map((point, j) => setShowPoints(true))
      );
    }
    return circuitList.map((circuit, i) =>
      circuit.points
        .filter((p) => p.isVisible)
        .map((point, j) => <PointCard point={point} key={j} />)
    );
  }
  console.log(circuitList);
  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez tout le patrimoine de la ${selectedMairie.name} sur l'annuaire Gwig!`}
        />
        <title>
          Gwig -{" Patrimoine de " + selectedMairie.gwigs_pop[0].name}
        </title>
      </Helmet>
      <div className="flex flex-col w-full">
        <MairieBanner
          mairie={selectedMairie}
          commune={selectedMairie.gwigs_pop[0].slug}
          variant={"change"}
        />
        <GwigSelector />
        <div className="flex font-bold text-md my-5">Circuits</div>
        <div className="flex flex-col gap-2 sm:gap-5 ">
          {loading ? <LoadingLogo /> : renderCircuits()}
        </div>
        {showPoint ? (
          <div className="flex font-bold text-md my-5">Points d'intérêts</div>
        ) : null}

        <div className="flex flex-col gap-2 sm:gap-5 ">
          {loading ? <LoadingLogo /> : renderPoints()}
        </div>
      </div>
    </PageLayout>
  );
};

export default PatrimoinePage;
