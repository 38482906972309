import React from "react";
import Image from "./Image";

const MapImage = ({ address, className = "" }) => {
  return (
    <div className={"relative w-full my-3  rounded" + className}>
      <a
        href={"http://maps.google.com/?q=" + address?.full}
        target="_blank"
        className="rounded"
      >
        <Image
          compress={false}
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${
            address.lat + "," + address.long
          }&zoom=11&size=400x200&scale=2&markers=color:red%7C${
            address.lat + "," + address.long
          }&key=AIzaSyDy7AZJBe0AR629fLGrBoktozU6QN73x_c`}
          className="rounded h-full relative"
        />
      </a>
      <p className="absolute bottom-0 left-0 right-0 p-3 text-center bg-white rounded ">
        {address.full}
      </p>
    </div>
  );
};

export default MapImage;
