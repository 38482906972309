import React from "react";
import {
  CalendarIcon,
  ArchiveIcon,
  ChatIcon,
  ClipboardListIcon,
} from "@heroicons/react/outline";
import PageLayout from "../components/PageLayout";
import MairieBanner from "../components/MairieBanner";
import useGetData from "../hooks/useGetData";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BigMapImage from "../components/BigMapImage";
import { Helmet } from "react-helmet";

const CommunePage = () => {
  window.scrollTo(0, 0);
  const selectedMairie = useSelector((state) => state.selectedMairie);
  const date = new Date();

  const { data: NbPosts } = useGetData({
    url: "/open/count/post",
    params: {
      params: {
        filter: {
          gwigs: { $in: selectedMairie.gwigs },
          publishDate: { $gte: date.setMonth(date.getMonth() - 1) },
        },
        sort: "-publishDate",
      },
    },
    dependencies: [],
  });

  const { data: NbEvents } = useGetData({
    url: "/open/count/event",
    params: {
      params: {
        filter: {
          gwigs: { $in: selectedMairie.gwigs },
          isActivity: false,
          datetime_out: { $gte: new Date() },
        },
        sort: "-publishDate",
      },
    },
    dependencies: [],
  });

  const { data: Nbbusiness } = useGetData({
    url: "/open/count/business",
    params: {
      params: {
        filter: {
          gwigs: { $in: selectedMairie.gwigs },
        },
        sort: "-publishDate",
      },
    },
    dependencies: [],
  });

  const { data: Nbsondage } = useGetData({
    url: "/open/count/business", //TODO change this
    params: {
      params: {
        filter: {
          gwigs: { $in: selectedMairie.gwigs },
        },
        sort: "-publishDate",
      },
    },
    dependencies: [],
  });
  const { data: Nbdemarche } = useGetData({
    url: "/open/count/attachment",
    params: {
      params: {
        filter: {
          gwigs: { $in: selectedMairie.gwigs },
        },
        sort: "-publishDate",
      },
    },
    dependencies: [],
  });

  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content="Retrouvez toute la richesse de votre commune sur Gwig"
        />
        <title>Gwig - {selectedMairie.name}</title>
      </Helmet>
      <div className="flex flex-col w-full">
        <MairieBanner
          mairie={selectedMairie}
          commune={selectedMairie?.gwigs_pop[0]?.slug}
          variant={"change"}
        />
        <div className="flex flex-col gap-5 mt-10 ">
          <div className="flex flex-col sm:flex-row justify-between gap-5">
            {NbEvents > 0 ? (
              <div className="flex flex-col justify-end bg-white rounded w-full h-56 ">
                <div className="flex h-3/4 text-salmon items-center justify-center">
                  <CalendarIcon className="h-12" />
                  <div className="flex flex-col ml-2">
                    <p className="text-lg font-bold">{NbEvents} événements </p>
                    <p>à venir</p>
                  </div>
                </div>
                <Link
                  to={`/evenements/${selectedMairie.gwigs_pop[0].slug}`}
                  className="flex rounded-b text-white h-1/4 justify-center items-center font-bold bg-salmon"
                >
                  Afficher l'agenda de la commune
                </Link>
              </div>
            ) : null}

            {NbPosts > 0 ? (
              <div className="flex flex-col justify-end bg-white rounded w-full h-56 ">
                <div className="flex h-3/4 text-accent-logo items-center justify-center">
                  <ChatIcon className="h-12" />
                  <div className="flex flex-col ml-2">
                    <p className="text-lg font-bold">{NbPosts} posts </p>
                    <p>publiés ce mois</p>
                  </div>
                </div>
                <Link
                  to={`/posts/${selectedMairie.gwigs_pop[0].slug}`}
                  className="flex rounded-b text-white h-1/4 justify-center items-center font-bold bg-accent-logo"
                >
                  Afficher le mur de la commune
                </Link>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col  bg-white rounded w-full h-56   ">
            <Link
              to={`/points/${selectedMairie.gwigs_pop[0].slug}`}
              className="flex-col rounded text-yellow  justify-center items-center text-center  bg-white mb-2"
            >
              <BigMapImage
                address={selectedMairie?.location}
                x={300}
                y={120}
                className="md:hidden"
              />
              <BigMapImage
                address={selectedMairie?.location}
                x={680}
                y={130}
                className="md:flex hidden"
              />
              <p className="mt-2">Points d'intérêts</p>
              <p className="text-lg font-bold ">Carte de la commune</p>
            </Link>
          </div>

          <div className="flex flex-col gap-5 ">
            <div className="flex flex-col sm:flex-row justify-between gap-5">
              {Nbbusiness > 0 ? (
                <div className="flex flex-col justify-end bg-white rounded w-full h-56 ">
                  <div className="flex h-3/4 text-pink items-center justify-center">
                    <CalendarIcon className="h-12" />
                    <div className="flex flex-col ml-2">
                      <p className="text-lg font-bold">{Nbbusiness} </p>
                      <p className="w-44">
                        artisans, commerçants et associations
                      </p>
                    </div>
                  </div>
                  <Link
                    to={`/annuaire/${selectedMairie.gwigs_pop[0].slug}`}
                    className="flex rounded-b text-white h-1/4 justify-center items-center font-bold bg-pink"
                  >
                    Afficher l’annuaire de la commune
                  </Link>
                </div>
              ) : null}

              {true ? (
                <div className="flex flex-col justify-end bg-white rounded w-full h-56 ">
                  <div className="flex h-3/4 text-mint items-center justify-center">
                    <ArchiveIcon className="h-12" />
                    <div className="flex flex-col ml-2">
                      <p className="text-lg font-bold"> démarches </p>
                      <p>en ligne</p>
                    </div>
                  </div>
                  <Link
                    to={`/demarches/${selectedMairie.gwigs_pop[0].slug}`}
                    className="flex rounded-b text-white h-1/4 justify-center items-center font-bold bg-mint"
                  >
                    Afficher les outils de la commune
                  </Link>
                </div>
              ) : null}
            </div>
            {false ? (
              <div className="flex flex-col sm:flex-row justify-between gap-5">
                <div className="flex flex-col justify-end bg-white rounded w-full h-56 ">
                  <div className="flex h-3/4 text-purple items-center justify-center">
                    <ClipboardListIcon className="h-12" />
                    <div className="flex flex-col ml-2">
                      <p className="text-lg font-bold">{Nbsondage} </p>
                      <p className="w-44">nouveau sondage</p>
                    </div>
                  </div>
                  <Link
                    to={`/posts/${selectedMairie.gwigs_pop[0].slug}`}
                    className="flex rounded-b text-white h-1/4 justify-center items-center font-bold bg-purple"
                  >
                    Afficher le mur de la commune
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default CommunePage;

//  const { data: lastPosts } = useGetData({
//    url: "/open/list/post",
//    params: {
//      params: {
//        filter: {
//          gwigs: { $in: selectedMairie.gwigs },
//        },
//        numberOfDays: 1,
//        populate: "createdBy",
//        sort: "-publishDate",
//        limit: 2,
//      },
//    },
//    dependencies: [],
//  });

//  const { data: events, loading: loading1 } = useGetData({
//    url: "/open/get-events",
//    params: {
//      params: {
//        filter: {
//          gwigs: { $in: selectedMairie.gwigs },
//        },
//        numberOfDays: 1,
//        limit: 2,
//        populate: "createdBy",
//      },
//    },
//  });

// function renderLastEvents() {
//   return events.map((event, i) => (
//     <div key={i} className="flex flex-col md:flex-row ">
//       {event.data.map((e, i) => (
//         <Link to={`/evenement/${e._id}`} key={i} className="mt-5 md:mt-0">
//           <EventCard gwig={selectedMairie} cardsize="small" event={e} />
//         </Link>
//       ))}
//     </div>
//   ));
// }

// function renderLastPosts() {
//   return lastPosts.map((post, i) => (
//     <div className="mt-5 md:mt-0 md:mx-2">
//       <PostCard
//         post={post}
//         gwig={selectedMairie}
//         cardsize="small"
//         commune={selectedMairie.gwigs_pop[0].slug}
//       />
//     </div>
//   ));
// }

// <div className="bg-white grid grid-cols-2 md:hidden">
//         <button
//           className={
//             " py-4 px-2 font-bold border-purple " +
//             (!tabs ? "border-b-2" : true)
//           }
//           onClick={() => settabs(false)}
//         >
//           Agenda et activités
//         </button>
//         <button
//           className={
//             " py-4 px-2 font-bold border-purple " +
//             (tabs ? "border-b-2" : true)
//           }
//           onClick={() => settabs(true)}
//         >
//           Posts locaux
//         </button>
//       </div>
//       <div className={"" + (tabs ? "hidden" : "bg-background md:hidden")}>
//         {events[0]?.data[0]?.id ? (
//           <>
//             {renderLastEvents()}
//             <div className=" bg-white rounded  h-10 w-auto ">
//               <Link to={`/evenements/` + selectedMairie.gwigs_pop[0].slug}>
//                 <p className=" mt-4 ">
//                   <h2 className="mt-2 text-center ">voir tout</h2>
//                 </p>
//               </Link>
//             </div>
//           </>
//         ) : (
//           <h>Aucun Evénement</h>
//         )}
//       </div>
//       <div
//         className={
//           "bg-background md:hidden " +
//           (!tabs ? "hidden" : "bg-background md:hidden")
//         }
//       >
//         {lastPosts[0]?._id ? (
//           <>
//             {renderLastPosts()}
//             <div className=" bg-white rounded  h-10 w-auto ">
//               <Link to={`/posts/` + selectedMairie.gwigs_pop[0].slug}>
//                 <p className=" mt-4 ">
//                   <h2 className="mt-2 text-center ">voir tout</h2>
//                 </p>
//               </Link>
//             </div>
//           </>
//         ) : (
//           <h>Aucun Post</h>
//         )}
//       </div>
