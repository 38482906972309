import React from "react";
import ReactMarkDown from "react-markdown";
import "./style.css";

const Text = ({ text }) => {
  return (
    <ReactMarkDown
      remarkPlugins={[]}
      children={text}
      className="markdown-style "
    />
  );
};

export default Text;
