import React from "react";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import { useParams, useLocation } from "react-router";
import BusinessContentElement from "../components/BusinessContentElement";
import BackButton from "../components/BackButton";
import BusinessHeader from "../components/BusinessHeader";
import LoadingLogo from "../components/LoadingLogo";
import { Helmet } from "react-helmet";

const BusinessCard = () => {
  window.scrollTo(0, 0);
  const { state } = useLocation();

  const { id } = useParams();

  console.log(id);
  console.log(state);

  var today = new Date();

  const { data: business, loading } = useGetData({
    initialValue: state?.data || {},
    url: "/open/read/business/" + id,
    params: {
      params: {
        populate: "gwigs createdBy",
      },
    },
  });
  console.log(business);

  return (
    <PageLayout>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez  ${business.name}  Gwig!`}
        />
        <title>
          {business.type === "association"
            ? "Association "
            : business.type === "commerce"
            ? "Boutique "
            : " "}
          {business.name}
        </title>
      </Helmet>
      <div className="w-full">
        <BackButton />
        {loading ? (
          <LoadingLogo className="mt-5 mb-96" />
        ) : (
          <>
            <BusinessHeader business={business} />
            <div className="flex flex-col gap-5 mt-5">
              <BusinessContentElement
                type="post"
                filter={{
                  createdBy: business._id,
                  datetime_in: { $gte: today.setMonth(today.getMonth() - 1) },
                }}
                initialNumber={1}
                title="Dernière actualité"
                isExpandable={true}
              />
              <BusinessContentElement
                type="event"
                filter={{
                  createdBy: business._id,
                  isActivity: false,
                  datetime_in: { $gte: new Date() },
                }}
                initialNumber={1}
                title="Prochains événements organisés"
                isExpandable={true}
              />
              <BusinessContentElement
                type="event"
                filter={{
                  createdBy: business._id,
                  isActivity: true,
                  datetime_in: { $gte: new Date() },
                }}
                initialNumber={1}
                title="Activités proposées"
                isExpandable={true}
              />
              <BusinessContentElement
                type="product"
                filter={{ createdBy: business._id }}
                initialNumber={20}
                title="Produits"
                isExpandable={false}
              />
            </div>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default BusinessCard;
