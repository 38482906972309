import React from "react";

export const GwigMapPin = ({ color = "#f65e39" }) => {
  return (
    <svg
      width="38"
      height="45"
      viewBox="0 0 38 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 41L18.0484 42.4146C18.6238 42.8016 19.3762 42.8016 19.9516 42.4146L19 41ZM19 41C19.9516 42.4146 19.952 42.4143 19.9525 42.4139L19.9538 42.4131L19.9573 42.4107L19.9685 42.4031L20.0069 42.377C20.0394 42.3546 20.0858 42.3226 20.1451 42.2812C20.2637 42.1983 20.434 42.0776 20.6486 41.9212C21.0778 41.6084 21.6852 41.152 22.4116 40.5683C23.8617 39.4031 25.7995 37.7199 27.7429 35.6499C31.5619 31.582 35.7049 25.7022 35.7049 19.1364C35.7049 14.6747 33.9486 10.3928 30.8176 7.23329C27.686 4.07326 23.4354 2.29514 19 2.29514C14.5646 2.29514 10.314 4.07326 7.18244 7.23329C4.0514 10.3928 2.29514 14.6747 2.29514 19.1364C2.29514 25.7022 6.43805 31.582 10.2571 35.6499C12.2005 37.7199 14.1383 39.4031 15.5884 40.5683C16.3148 41.152 16.9222 41.6084 17.3514 41.9212C17.566 42.0776 17.7363 42.1983 17.8549 42.2812C17.9142 42.3226 17.9606 42.3546 17.9931 42.377L18.0315 42.4031L18.0427 42.4107L18.0462 42.4131L18.0475 42.4139C18.048 42.4143 18.0484 42.4146 19 41Z"
        fill={color}
        stroke="white"
        stroke-width="3.40972"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
