import { useEffect, useState } from "react";
import config from "../config";
import moment from "moment";

const WeatherCard = ({ event, eventday }) => {
  console.log(event);
  console.log(eventday);
  const [location, setLocation] = useState(event?.location);
  const [weather, setWeather] = useState({});

  const key = config.WeatherApiKey;
  const apiUrl = `https://api.openweathermap.org/data/2.5/onecall?lat=${location.lat}&lon=${location.long}&exclude=hourly,minutely,current&appid=${key}`;

  // https://api.openweathermap.org/data/2.5/forecast?q=${location}&appid=${key} toute les 3h 5 jours

  useEffect(() => {
    fetch(apiUrl)
      .then((res) => res.json())
      .then((data) => setWeather(data));
  }, [apiUrl]);

  function kelvinToCelsius(k) {
    return (k - 273.15).toFixed(2);
  }
  //if we have a state
  if (eventday) {
    for (let i = 0; i < weather?.daily?.length; i++) {
      var date = new Date(weather.daily[i].dt * 1000);
      if (
        moment(date).format("MMMM Do") === moment(eventday).format("MMMM Do")
      ) {
        return (
          <div className="flex items-center">
            <h4 className="mr-3">Météo du jour</h4>
            <div>{kelvinToCelsius(weather.daily[i].temp.day)}° Celsius</div>
            <img
              src={`http://openweathermap.org/img/w/${weather.daily[i].weather[0].icon}.png`}
              alt="weather status icon"
              className="weather-icon"
            />
          </div>
        );
      }
    }
  }
  //if it's a simple event
  if (weather.daily && !event.isRepeating) {
    for (let i = 0; i < weather.daily.length; i++) {
      var date = new Date(weather.daily[i].dt * 1000);
      if (
        moment(date).format("MMMM Do") ===
        moment(event.datetime_in).format("MMMM Do")
      ) {
        return (
          <div className="flex items-center">
            <h4 className="mr-3">Météo du jour</h4>
            <div>{kelvinToCelsius(weather.daily[i].temp.day)}° Celsius</div>
            <img
              src={`http://openweathermap.org/img/w/${weather.daily[i].weather[0].icon}.png`}
              alt="weather status icon"
              className="weather-icon"
            />
          </div>
        );
      }
    }

    return null;
    // <div className="flex items-center">
    //   Météo disponible 1 semaine à l'avance
    // </div>
  }
  //if it's a repeating event
  if (weather.daily && event.isRepeating) {
    console.log(weather);

    var datein = new Date(event.datetime_in);
    var dateout = new Date(event.datetime_out);

    var i = datein;
    console.log(i.getDay());
    do {
      i.setDate(i.getDate() + 1);
      if (i.getDay() === event.repeats_on + 1) {
        for (let j = 0; j < weather.daily.length; j++) {
          var date1 = new Date(weather.daily[j].dt * 1000);

          if (moment(date1).format("MMMM Do") === moment(i).format("MMMM Do")) {
            return (
              <div className="flex items-center">
                <h4 className="mr-3">Météo du jour</h4>
                <div>{kelvinToCelsius(weather.daily[j].temp.day)}° Celsius</div>
                <img
                  src={`http://openweathermap.org/img/w/${weather.daily[j].weather[0].icon}.png`}
                  alt="weather status icon"
                  className="weather-icon"
                />
              </div>
            );
          }
        }
      }
    } while (moment(i).format("MMMM Do") !== moment(dateout).format("MMMM Do"));
  }
  return null;
};

export default WeatherCard;
