import React from "react";
import PageLayout from "../components/PageLayout";
import useGetData from "../hooks/useGetData";
import { useParams } from "react-router";
import Text from "../components/Text";
import BackButton from "../components/BackButton";
import { Helmet } from "react-helmet";

const DemarchePage = () => {
  window.scrollTo(0, 0);
  const { id } = useParams();
  const { data: attachment = {}, loading } = useGetData({
    url: "/open/read/attachment/" + id,
    params: {
      params: {},
    },
  });

  if (!attachment) {
    return (
      <PageLayout>
        <p>Aucun élément trouvé :(</p>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Helmet>
        <meta name="description" content={``} />
        <title>Gwig -{" Démarches en ligne "}</title>
      </Helmet>
      <div className=" flex flex-col w-full">
        <BackButton />
        <div className="bg-white rounded w-full flex-col p-5">
          <h1 className="text-lg">{attachment.title}</h1>
          <Text text={attachment.description} />
        </div>
      </div>
    </PageLayout>
  );
};

export default DemarchePage;
