import React from "react";
import { useFormikContext } from "formik";

const SubmitButton = ({ text = "Sauvegarder", className = "", disabled }) => {
  const { setFieldValue, isValid, isSubmitting, dirty } =
    useFormikContext() || {};

  return (
    <button
      className={
        "h-full w-full bg-green text-white sm:rounded px-5 disabled:bg-gray-400 font-medium disabled:text-gray-100 " +
        className
      }
      disabled={!dirty || !isValid || disabled}
      type="submit"
    >
      {text}
    </button>
  );
};

export default SubmitButton;
