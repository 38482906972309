

export const LogoInstagram = ({color}) => {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-1">
            <path d="M38.4834 20C38.4834 30.5141 30.1496 39 19.915 39C9.68047 39 1.34668 30.5141 1.34668 20C1.34668 9.48591 9.68047 1 19.915 1C30.1496 1 38.4834 9.48591 38.4834 20Z" fill="white" stroke={color} strokeWidth="2"/>
            <path d="M23.826 12H15.9986C13.8372 12 12.085 13.7909 12.085 16V24C12.085 26.2091 13.8372 28 15.9986 28H23.826C25.9874 28 27.7396 26.2091 27.7396 24V16C27.7396 13.7909 25.9874 12 23.826 12Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.0439 19.4962C23.1405 20.162 23.0293 20.842 22.7259 21.4395C22.4226 22.0369 21.9427 22.5214 21.3545 22.824C20.7663 23.1266 20.0996 23.2319 19.4494 23.125C18.7993 23.0181 18.1986 22.7043 17.733 22.2284C17.2673 21.7524 16.9603 21.1385 16.8557 20.474C16.7511 19.8095 16.8541 19.1282 17.1502 18.5269C17.4463 17.9257 17.9203 17.4352 18.5048 17.1252C19.0894 16.8152 19.7547 16.7015 20.4061 16.8002C21.0706 16.9009 21.6858 17.2174 22.1608 17.7029C22.6358 18.1884 22.9454 18.8171 23.0439 19.4962Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.2197 15.6001H24.2277" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}


export const LogoFacebook = ({color}) => {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-1">
            <path d="M38.4014 20C38.4014 30.5141 30.0676 39 19.833 39C9.59844 39 1.26465 30.5141 1.26465 20C1.26465 9.48591 9.59844 1 19.833 1C30.0676 1 38.4014 9.48591 38.4014 20Z" fill="white" stroke={color} strokeWidth="2"/>
            <path d="M24.7255 10H21.7902C20.4928 10 19.2484 10.5268 18.331 11.4645C17.4136 12.4021 16.8981 13.6739 16.8981 15V18H13.9629V22H16.8981V30H20.8118V22H23.7471L24.7255 18H20.8118V15C20.8118 14.7348 20.9149 14.4804 21.0984 14.2929C21.2819 14.1054 21.5307 14 21.7902 14H24.7255V10Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}


export const LogoSiteWeb =({color}) => {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-1">
            <path d="M38.3232 20C38.3232 30.5141 29.9894 39 19.7549 39C9.52031 39 1.18652 30.5141 1.18652 20C1.18652 9.48591 9.52031 1 19.7549 1C29.9894 1 38.3232 9.48591 38.3232 20Z" fill="white" stroke={color} strokeWidth="2"/>
            <path d="M19.5265 28.3433C24.2123 28.3433 28.0109 24.4608 28.0109 19.6716C28.0109 14.8824 24.2123 11 19.5265 11C14.8406 11 11.042 14.8824 11.042 19.6716C11.042 24.4608 14.8406 28.3433 19.5265 28.3433Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.042 19.6719H28.0109" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.5276 11C21.6498 13.3746 22.8558 16.4562 22.9214 19.6716C22.8558 22.887 21.6498 25.9687 19.5276 28.3433C17.4054 25.9687 16.1993 22.887 16.1338 19.6716C16.1993 16.4562 17.4054 13.3746 19.5276 11V11Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export const LogoLocalisation =({color}) => {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"  className="mx-1">
            <ellipse cx="19.8331" cy="20.0002" rx="19.5685" ry="20.0002" fill={color}/>
            <path d="M28.4889 17.8122C28.4889 24.5036 20.0714 30.2391 20.0714 30.2391C20.0714 30.2391 11.6538 24.5036 11.6538 17.8122C11.6538 15.5305 12.5407 13.3422 14.1193 11.7288C15.6979 10.1154 17.8389 9.20898 20.0714 9.20898C22.3039 9.20898 24.4449 10.1154 26.0235 11.7288C27.6021 13.3422 28.4889 15.5305 28.4889 17.8122Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20.071 20.6803C21.6206 20.6803 22.8768 19.3964 22.8768 17.8126C22.8768 16.2288 21.6206 14.9448 20.071 14.9448C18.5214 14.9448 17.2651 16.2288 17.2651 17.8126C17.2651 19.3964 18.5214 20.6803 20.071 20.6803Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export const LogoTelephone =({color}) => {
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"  className="mx-1">
            <ellipse cx="19.755" cy="20.0002" rx="19.5685" ry="20.0002" fill={color}/>
            <path d="M28.5614 23.7274V26.2866C28.5624 26.5242 28.5147 26.7593 28.4214 26.977C28.328 27.1947 28.1912 27.3901 28.0196 27.5507C27.8479 27.7113 27.6453 27.8336 27.4247 27.9097C27.204 27.9858 26.9702 28.0141 26.7383 27.9927C24.1648 27.7075 21.6928 26.8105 19.5209 25.3738C17.5002 24.0641 15.787 22.3166 14.503 20.2554C13.0896 18.0299 12.21 15.4961 11.9355 12.8593C11.9146 12.6234 11.9421 12.3856 12.0162 12.1612C12.0903 11.9367 12.2095 11.7304 12.366 11.5555C12.5226 11.3805 12.7131 11.2408 12.9255 11.1451C13.1379 11.0494 13.3676 10.9998 13.5998 10.9996H16.1087C16.5146 10.9955 16.9081 11.1421 17.2158 11.4121C17.5235 11.682 17.7245 12.0569 17.7813 12.4669C17.8872 13.2859 18.0836 14.09 18.3668 14.864C18.4793 15.1693 18.5036 15.5012 18.4369 15.8202C18.3702 16.1392 18.2153 16.432 17.9904 16.664L16.9283 17.7474C18.1188 19.8831 19.8524 21.6514 21.9462 22.8658L23.0083 21.7824C23.2357 21.553 23.5228 21.395 23.8355 21.3269C24.1483 21.2589 24.4736 21.2837 24.7729 21.3985C25.5317 21.6873 26.3201 21.8876 27.123 21.9957C27.5292 22.0541 27.9002 22.2628 28.1655 22.5821C28.4307 22.9014 28.5716 23.309 28.5614 23.7274Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}