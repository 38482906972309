import { LoadingIcon } from "../../assets/svg/LoadingIcon";
import ReturnMenuBouton from "./ReturnMenuBouton";

const MenuHeader = ({ loading, title, setDataList }) => {
  return (
    <div className="flex-col rounded-t bg-conso   text-white   h-16">
      <ReturnMenuBouton setDataList={setDataList} />
      <div className="flex justify-center items-center font-marker text-md text-center ">
        {title}
        {loading ? (
          <div className=" animate-spin ml-2">
            <LoadingIcon />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MenuHeader;
