import React from "react";

export const LoadingIcon = ({ color = "#ffffff", bgColor = "#F65E39" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5ZM5.3452 22.5C5.3452 31.9743 13.0257 39.6548 22.5 39.6548C31.9743 39.6548 39.6548 31.9743 39.6548 22.5C39.6548 13.0257 31.9743 5.3452 22.5 5.3452C13.0257 5.3452 5.3452 13.0257 5.3452 22.5Z"
        fill={bgColor}
        fill-opacity="0.1"
      />
      <path
        d="M44.6758 26.3055C45.2425 23.0036 45.0646 19.6167 44.1552 16.3923C43.2457 13.1679 41.6277 10.1872 39.4193 7.66799C37.2108 5.14873 34.4676 3.15445 31.39 1.83074C28.3124 0.507036 24.978 -0.112688 21.6303 0.0168164L21.8373 5.36909C24.3881 5.27042 26.9287 5.74261 29.2737 6.7512C31.6186 7.75979 33.7088 9.27932 35.3915 11.1988C37.0742 13.1184 38.3071 15.3895 39 17.8463C39.6929 20.3031 39.8285 22.8837 39.3967 25.3996L44.6758 26.3055Z"
        fill={color}
        fill-opacity="0.79"
      />
    </svg>
  );
};
