import React from "react";
import moment from "moment";
import Image from "./Image";
import CreatedByDisplay from "./CreatedByDisplay";

const EventCard = ({ event, cardsize }) => {
  return cardsize === "small" ? (
    <div className="grid grid-rows-2 md:h-52 bg-white rounded  ">
      <div className="relative  gap-1 w-full">
        <Image
          src={event.cover_image}
          size="med"
          className="h-24 object-cover rounded-t w-full"
        />
        <div className="rounded absolute grid grid-rows-2 -bottom-3 left-5 h-12 w-12  shadow-md border-conso">
          <div className="rounded-t bg-pink text-white text-15 text-center">
            {moment(event.datetime_in).format("MMM")}
          </div>
          <div className="rounded-b bg-white text-15 text-center">
            {moment(event.datetime_in).format("Do")}
          </div>
        </div>
      </div>
      <div className="grid grid-rows-2 w-full">
        <p className="text-15  col-span-2 font-bold ml-4 mt-6 line-clamp-1">
          {event.title}
        </p>
        <div className="flex items-center ">
          <p className=" text-15  ml-4 mb-6 text-purple ">
            {moment(event.datetime_in).format("HH:mm")}-{""}
            {moment(event.datetime_out).format("HH:mm")}
          </p>
          <p className=" text-15  ml-2 mb-6 text-conso line-clamp-1">
            {event.location.city}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <>
      {/*big card */}
      <div className="hidden md:flex bg-white rounded ">
        <Image
          src={event.cover_image}
          size="med"
          className="w-36 h-44 object-cover rounded-l "
        />
        <div className="flex flex-col ml-3 mt-3 justify-between">
          <div className="flex flex-col">
            <p className="text-md  font-bold">{event.title}</p>
            <p className="text-base text-conso">{event.location.city}</p>
            <div className="flex">
              <div className="flex rounded text-purple bg-purple bg-opacity-20 mt-3">
                <p className="text-base mx-4">
                  {"De "}
                  {moment(event.datetime_in).format("HH")}
                  {"h"}
                  {moment(event.datetime_in).format("mm") === "00"
                    ? null
                    : moment(event.datetime_in).format("mm")}
                  {" à "}
                  {moment(event.datetime_out).format("HH")}
                  {"h"}
                  {moment(event.datetime_out).format("mm") === "00"
                    ? null
                    : moment(event.datetime_out).format("mm")}
                </p>
              </div>
            </div>
          </div>

          <CreatedByDisplay business={event.createdBy} className="mb-3 " />
        </div>
      </div>
      {/*small card */}
      <div className="grid grid-rows-2 md:h-52 md:w-72 bg-white rounded md:hidden ">
        <div className="relative  gap-1 w-full">
          <Image
            alt=""
            src={event.cover_image}
            size="med"
            className="h-24 object-cover rounded-t w-full"
          />
          <div className="rounded absolute grid grid-rows-2 -bottom-3 left-5 h-12 w-12  shadow-md border-conso">
            <div className="rounded-t bg-pink text-white text-15 text-center">
              {moment(event.datetime_in).format("MMM")}
            </div>
            <div className="rounded-b bg-white text-15 text-center">
              {moment(event.datetime_in).format("Do")}
            </div>
          </div>
        </div>
        <div className="grid grid-rows-2 w-full">
          <p className="text-15  col-span-2 font-bold ml-4 mt-6 line-clamp-1">
            {event.title}
          </p>
          <div className="flex items-center ">
            <p className=" text-15  ml-4 mb-6 text-purple ">
              {moment(event.datetime_in).format("HH:mm")}-{""}
              {moment(event.datetime_out).format("HH:mm")}
            </p>
            <p className=" text-15  ml-2 mb-6 text-conso line-clamp-1">
              {event.location.city}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
