import { useHistory, useLocation, useParams } from "react-router";
import React, { useState, useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import ProductPanel from "../components/ProductPanel";
import axios from "axios";
import CircuitPanel from "../components/CircuitPanel";
import BackButton from "../components/BackButton";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const ItemModalPage = () => {
  const { selectedMairie, selectedGwigs } = useSelector((state) => state);
  const history = useHistory();
  const { state = {} } = useLocation();
  const [item, setItem] = useState(state.data);
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { type, id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .post("/open/read/" + type + "/" + id, {
        params: {
          populate: "createdBy points",
          sort: "-publishDate",
        },
      })
      .then((res) => {
        if (res.data.success) {
          setItem(res.data.data);
          setLoading(false);
          const filter =
            type === "circuit"
              ? {
                  gwigs: {
                    $in: [
                      ...selectedMairie.gwigs,
                      ...selectedGwigs.map((i) => i._id),
                    ],
                  },
                }
              : {
                  createdBy: {
                    _id: res.data.data.createdBy._id,
                  },
                };
          axios
            .post("/open/list/" + type, {
              params: {
                filter: filter,
                populate: "createdBy points",
                sort: "-publishDate",
              },
            })
            .then((res) => {
              if (res.data.success) {
                setItemList(res.data.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  let back = () => {
    history.goBack();
  };

  function changeSlide(e, payload) {
    // 0, 1, 2
    let i = itemList.findIndex((_i) => _i._id === item._id);
    if (i === itemList.length - 1 && payload > 0) {
      i = 0;
    } else if (i === 0 && payload < 0) {
      i = itemList.length - 1;
    } else {
      i = i + payload;
    }

    setItem(itemList[i]);
    e.stopPropagation();
  }

  function renderMobileArrow(orientation) {
    return (
      <>
        <button
          className={
            "flex items-center text-metal bg-white p-1 rounded w-32 justify-center m-2 shadow-sm"
          }
          disabled={!itemList.length}
          onClick={(e) =>
            orientation === "left" ? changeSlide(e, -1) : changeSlide(e, 1)
          }
        >
          {orientation === "left" ? (
            <p className="flex flex-row  items-center">
              <ChevronLeftIcon className="h-10  " />
              précedent
            </p>
          ) : (
            <p className="flex flex-row  items-center">
              suivant
              <ChevronRightIcon className="h-10  " />
            </p>
          )}
        </button>
      </>
    );
  }

  function renderArrow(orientation) {
    return (
      <>
        <button
          className={
            "hidden md:flex items-center transition-opacity opacity-50 hover:opacity-100" +
            (itemList.length ? "" : "opacity-0")
          }
          disabled={!itemList.length}
          onClick={(e) =>
            orientation === "left" ? changeSlide(e, -1) : changeSlide(e, 1)
          }
        >
          {orientation === "left" ? (
            <ChevronLeftIcon className="h-28  text-white" />
          ) : (
            <ChevronRightIcon className="h-28  text-white" />
          )}
        </button>
      </>
    );
  }

  function renderPanel() {
    console.log(item);
    switch (type) {
      case "product":
        return (
          <>
            <Helmet>
              <meta
                name="description"
                content="Gwig est l'application qui regroupe toute la vie municipale, commerciale et associative de votre commune. Evénements, activités, produits... tout le dynamisme local est référencé sur Gwig, l'application citoyenne des communes connectées !"
              />
              <title>Gwig - Produit : {item.name}</title>
            </Helmet>
            <ProductPanel data={item}></ProductPanel>
          </>
        );
      case "circuit":
        return (
          <>
            <Helmet>
              <meta
                name="description"
                content="Gwig est l'application qui regroupe toute la vie municipale, commerciale et associative de votre commune. Evénements, activités, produits... tout le dynamisme local est référencé sur Gwig, l'application citoyenne des communes connectées !"
              />
              <title>
                Gwig - Circuits de {selectedMairie.gwigs_pop[0].name}
              </title>
            </Helmet>
            <CircuitPanel data={item}></CircuitPanel>
          </>
        );
      default:
        return "error modal";
    }
  }

  return (
    <div
      className="fixed inset-0 top-16 z-30  md:flex bg-black bg-opacity-50 justify-center md:items-center items-end md:p-5   "
      onClick={back}
    >
      {renderArrow("left")}
      <div className="flex md:hidden fixed top-0 left-3 z-40">
        <BackButton />
      </div>
      {item?._id ? renderPanel() : null}
      <div className="flex flex-row inset-x-0 fixed bottom-0 md:hidden justify-between">
        {renderMobileArrow("left")}
        {renderMobileArrow("right")}
      </div>
      {renderArrow("right")}
    </div>
  );
};

export default ItemModalPage;
