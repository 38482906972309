import Image from "./Image";
import LikesNb from "./LikesNb";

const ProductCard = ({ product }) => {
  return (
    <div className="flex flex-col bg-white rounded">
      <Image
        src={product.cover_image}
        size="med"
        className="rounded object-cover mx-5 mt-5"
      />
      <div className="text-md font-bold mx-5 mt-3">{product.name}</div>
      <div className="flex justify-between  items-center mx-5 mt-3 mb-5">
        <p className="text-conso font-bold text-md">
          {product.price > 0 ? product.price + "€" : ""}
        </p>
        {/* <LikesNb type="product" likes={product.likes_nb} _id={product._id} /> */}
      </div>
    </div>
  );
};

export default ProductCard;
