import React from "react";

export const ParkingIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 12 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05176 8.6582V13H0.966797V0.203125H6.07324C7.05176 0.203125 7.91602 0.384766 8.66602 0.748047C9.42188 1.10547 10.0049 1.61816 10.415 2.28613C10.8311 2.94824 11.0391 3.70117 11.0391 4.54492C11.0391 5.79297 10.5908 6.79199 9.69434 7.54199C8.80371 8.28613 7.5791 8.6582 6.02051 8.6582H4.05176ZM4.05176 6.27637H6.07324C6.6709 6.27637 7.125 6.12695 7.43555 5.82812C7.75195 5.5293 7.91016 5.10742 7.91016 4.5625C7.91016 3.96484 7.74902 3.4873 7.42676 3.12988C7.10449 2.77246 6.66504 2.59082 6.1084 2.58496H4.05176V6.27637Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const BikeParkingIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 15 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.73299 8.86426V12H3.50497V2.75781H7.19296C7.89966 2.75781 8.52385 2.889 9.06551 3.15137C9.61141 3.40951 10.0325 3.77979 10.3287 4.26221C10.6292 4.7404 10.7794 5.28418 10.7794 5.89355C10.7794 6.79492 10.4557 7.51644 9.80819 8.05811C9.16496 8.59554 8.28052 8.86426 7.15487 8.86426H5.73299ZM5.73299 7.14404H7.19296C7.6246 7.14404 7.95256 7.03613 8.17684 6.82031C8.40536 6.60449 8.51962 6.2998 8.51962 5.90625C8.51962 5.47461 8.40324 5.12972 8.17049 4.87158C7.93775 4.61344 7.62036 4.48226 7.21835 4.47803H5.73299V7.14404Z"
        fill="currentColor"
      />
      <circle cx="2.52829" cy="19.0712" r="2.02829" stroke="currentColor" />
      <circle cx="11.8537" cy="19.0712" r="2.2598" stroke="currentColor" />
      <path
        d="M2.52829 19.2786L4.85963 14.8757M4.85963 14.8757H9.52231M4.85963 14.8757L7.19097 19.2786M4.85963 14.8757L5.44247 13.3443H3.3054L2.52829 14.11M9.52231 14.8757L11.8537 19.2786H7.19097M9.52231 14.8757L7.19097 19.2786M9.52231 14.8757V14.3014H10.688"
        stroke="currentColor"
      />
    </svg>
  );
};

export const ToiletsIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.36685 0C7.90476 0 7.53017 0.374597 7.53017 0.836685V15.897C7.53017 16.3591 7.90476 16.7337 8.36685 16.7337C8.82894 16.7337 9.20354 16.3591 9.20354 15.897V0.836685C9.20354 0.374597 8.82894 0 8.36685 0ZM4.60177 3.34674C4.60177 4.27092 3.85258 5.02011 2.9284 5.02011C2.00422 5.02011 1.25503 4.27092 1.25503 3.34674C1.25503 2.42256 2.00422 1.67337 2.9284 1.67337C3.85258 1.67337 4.60177 2.42256 4.60177 3.34674ZM5.72866 11.2953C5.8568 10.8769 5.8568 10.19 5.8568 9.20354C5.8568 7.58623 5.02011 5.8568 2.9284 5.8568C0.836685 5.8568 0 7.58623 0 9.20354C0 10.19 0 10.8769 0.128133 11.2953C0.317996 11.5392 0.604387 11.7156 0.93887 11.8416C1.0304 12.3344 1.16387 13.0616 1.25503 13.5961C1.41577 14.5386 1.67337 15.4787 1.67337 15.4787C2.47132 16.048 3.38548 16.048 4.18343 15.4787C4.18343 15.4787 4.4384 14.4947 4.60177 13.5961L4.92098 11.8405C5.25415 11.7145 5.53938 11.5384 5.72866 11.2953ZM16.9049 10.9265L16.9049 10.9265C16.8219 10.3107 16.7337 9.65641 16.7337 9.20354C16.7337 7.58623 15.897 5.8568 13.8053 5.8568C11.7136 5.8568 10.8769 7.58623 10.8769 9.20354C10.8769 9.65643 10.7887 10.3107 10.7057 10.9265C10.608 11.652 10.5174 12.324 10.5867 12.5503C10.8555 12.8956 11.425 13.0522 12.0493 13.1225C12.0788 13.289 12.107 13.4498 12.1319 13.5961C12.2927 14.5386 12.5503 15.4787 12.5503 15.4787C13.3482 16.048 14.2624 16.048 15.0603 15.4787C15.0603 15.4787 15.3153 14.4947 15.4787 13.5961L15.5648 13.1221C16.1877 13.0516 16.7556 12.895 17.0239 12.5503C17.0932 12.324 17.0026 11.652 16.9049 10.9265ZM13.8053 5.02011C14.7295 5.02011 15.4787 4.27092 15.4787 3.34674C15.4787 2.42256 14.7295 1.67337 13.8053 1.67337C12.8811 1.67337 12.1319 2.42256 12.1319 3.34674C12.1319 4.27092 12.8811 5.02011 13.8053 5.02011ZM15.6202 4.69247C15.4566 4.5509 15.4207 4.3112 15.5355 4.12785C15.6515 3.94271 15.886 3.87112 16.0856 3.95993L16.9376 4.33902C17.2712 4.48747 17.3848 4.90455 17.1726 5.20169C16.975 5.47827 16.5824 5.52506 16.3254 5.30265L15.6202 4.69247Z"
      fill="currentColor"
    />
  </svg>
);

export const DefibrillatorIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.91711 1.30047C12.3092 -3.21438 23.2906 4.68612 7.91711 14.845C-7.45636 4.68711 3.52497 -3.21438 7.91711 1.30047ZM8.11071 2.86427L5.93854 7.74379C5.87966 7.87606 5.97647 8.02512 6.12125 8.02512H7.50481V11.9319C7.50481 12.0403 7.65307 12.0712 7.69645 11.9719L9.89859 6.93083C9.95631 6.79871 9.8595 6.65077 9.71532 6.65077H8.30207V2.90494C8.30207 2.79697 8.15462 2.76563 8.11071 2.86427Z"
      fill="currentColor"
    />
  </svg>
);

export const PicnicTableIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.846558"
      width="14.4912"
      height="1.61844"
      rx="0.809219"
      fill="currentColor"
    />
    <rect
      y="5.60228"
      width="16.1844"
      height="1.61844"
      rx="0.809219"
      fill="currentColor"
    />
    <rect
      x="1.61835"
      y="10.2966"
      width="10.2562"
      height="1.61844"
      rx="0.809219"
      transform="rotate(-82.6842 1.61835 10.2966)"
      fill="currentColor"
    />
    <rect
      width="10.2005"
      height="1.61844"
      rx="0.809219"
      transform="matrix(-0.127338 -0.991859 -0.991859 0.127338 14.5564 10.2966)"
      fill="currentColor"
    />
  </svg>
);

export const ElectricPointIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1017 0.320168C12.0773 0.169774 12.1794 0.0280473 12.3298 0.00361242C12.4802 -0.0208224 12.6219 0.0812877 12.6463 0.231682L12.7708 0.997716L12.7731 0.997339C12.7731 0.997339 13.4628 2.88456 12.5348 3.5364C12.694 4.514 12.8527 5.99782 12.828 7.39377C12.8152 8.11211 12.7537 8.82518 12.6109 9.44124C12.4714 10.0429 12.2382 10.6256 11.8269 11.0048C11.136 11.6418 10.4298 11.8237 9.80454 11.722V13.0144C9.80454 13.0423 9.80397 13.07 9.80283 13.0977C10.4863 13.4157 10.9601 14.1085 10.9601 14.912V15.3802H0V14.912C0 14.1085 0.473792 13.4157 1.15723 13.0977C1.1561 13.07 1.15553 13.0423 1.15553 13.0144V2.484C1.15553 1.37943 2.05096 0.484001 3.15553 0.484001H7.80454C8.90911 0.484001 9.80454 1.37943 9.80454 2.484V10.6959C9.85189 10.7113 9.90108 10.7238 9.95183 10.7327C10.2395 10.7832 10.6489 10.7307 11.1491 10.2696C11.338 10.0954 11.5124 9.7517 11.6367 9.21545C11.7577 8.69358 11.816 8.05719 11.8281 7.37604C11.8513 6.06649 11.7028 4.65802 11.552 3.72309C10.3997 3.45922 10.4586 1.37338 10.4586 1.37338L10.459 1.37333L10.3345 0.607291C10.3101 0.456897 10.4122 0.31517 10.5626 0.290735C10.713 0.2663 10.8547 0.36841 10.8792 0.518804L11.0036 1.28484L12.2262 1.0862L12.1017 0.320168ZM2.45857 2.1717C2.45857 1.89556 2.68243 1.6717 2.95857 1.6717H8.0015C8.27764 1.6717 8.5015 1.89556 8.5015 2.1717V5.01726C8.5015 5.29341 8.27764 5.51727 8.0015 5.51727H2.95857C2.68243 5.51727 2.45857 5.29341 2.45857 5.01727V2.1717ZM6.25327 6.59506C6.34533 6.25019 5.86586 6.06173 5.69846 6.37698L3.67932 10.1794C3.58555 10.356 3.68751 10.5732 3.88329 10.6138L4.93075 10.8313C5.09945 10.8663 5.20404 11.036 5.1596 11.2024L4.7068 12.8986C4.61474 13.2435 5.09421 13.4319 5.26161 13.1167L7.28074 9.31429C7.37452 9.1377 7.27255 8.92051 7.07678 8.87986L6.02932 8.66236C5.86062 8.62733 5.75603 8.45772 5.80047 8.29125L6.25327 6.59506Z"
      fill="currentColor"
    />
  </svg>
);

export const ParkIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="8.5863"
      y="15.9333"
      width="10.6206"
      height="0.82136"
      rx="0.41068"
      fill="currentColor"
    />
    <rect
      x="9.34195"
      y="14.5618"
      width="9.08855"
      height="0.813825"
      rx="0.406912"
      fill="currentColor"
    />
    <rect
      x="9.34195"
      y="13.1904"
      width="9.08855"
      height="0.813825"
      rx="0.406912"
      fill="currentColor"
    />
    <rect
      x="10.0872"
      y="16.2573"
      width="1.61258"
      height="0.745301"
      rx="0.37265"
      transform="rotate(90 10.0872 16.2573)"
      fill="currentColor"
    />
    <rect
      x="10.8326"
      y="15.6544"
      width="0.602833"
      height="0.745301"
      rx="0.301416"
      transform="rotate(90 10.8326 15.6544)"
      fill="currentColor"
    />
    <rect
      x="10.8326"
      y="14.2604"
      width="0.602833"
      height="0.745301"
      rx="0.301416"
      transform="rotate(90 10.8326 14.2604)"
      fill="currentColor"
    />
    <rect
      x="10.8326"
      y="12.8889"
      width="0.602833"
      height="0.745301"
      rx="0.301416"
      transform="rotate(90 10.8326 12.8889)"
      fill="currentColor"
    />
    <rect
      x="17.6852"
      y="12.8889"
      width="0.602833"
      height="0.745301"
      rx="0.301416"
      transform="rotate(90 17.6852 12.8889)"
      fill="currentColor"
    />
    <rect
      x="17.6852"
      y="14.2604"
      width="0.602833"
      height="0.745301"
      rx="0.301416"
      transform="rotate(90 17.6852 14.2604)"
      fill="currentColor"
    />
    <rect
      x="17.6852"
      y="15.6544"
      width="0.602833"
      height="0.745301"
      rx="0.301416"
      transform="rotate(90 17.6852 15.6544)"
      fill="currentColor"
    />
    <rect
      x="18.4305"
      y="16.2573"
      width="1.61258"
      height="0.745301"
      rx="0.37265"
      transform="rotate(90 18.4305 16.2573)"
      fill="currentColor"
    />
    <path
      d="M4.34647 17.0636C4.34647 17.0636 5.04867 16.1586 5.04867 12.9456C5.04867 9.73256 4.61934 8.35133 4.61934 8.35133C4.61934 8.35133 5.58588 8.09947 6.02007 7.35355C6.02007 7.35355 6.72747 8.35133 7.24076 8.4593C7.24076 8.4593 7.21251 9.84873 7.22399 10.5364C7.23973 11.4794 7.309 12.0052 7.37282 12.9456C7.48208 14.5553 7.69531 17.0636 7.69531 17.0636H4.34647Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <circle cx="4.04616" cy="6.97883" r="1.97472" fill="currentColor" />
    <circle cx="6.32118" cy="5.37883" r="1.97472" fill="currentColor" />
    <circle cx="8.45116" cy="6.63547" r="2.56492" fill="currentColor" />
    <circle cx="10.7212" cy="5.81118" r="1.54237" fill="currentColor" />
    <circle cx="4.04616" cy="4.07055" r="2.56492" fill="currentColor" />
    <circle cx="6.87542" cy="2.12503" r="2.12503" fill="currentColor" />
    <circle cx="8.59618" cy="4.07055" r="2.12503" fill="currentColor" />
    <circle cx="2.12503" cy="5.52915" r="2.12503" fill="currentColor" />
  </svg>
);
