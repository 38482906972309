import Image from "./Image";
import { ClockIcon } from "@heroicons/react/outline";
import { IconDistance } from "./Icons";
import config from "../config";

const CircuitCard = ({ circuit }) => {
  const returnMapImage = () => {
    if (!circuit.path?.length) return "null";
    const nw = {
      lat: Math.max(...circuit.path.map((i) => i.lat)),
      lng: Math.min(...circuit.path.map((i) => i.long)),
    };
    //point le plus au sud-est
    const se = {
      lat: Math.min(...circuit.path.map((i) => i.lat)),
      lng: Math.max(...circuit.path.map((i) => i.long)),
    };
    //point du centre
    const center = (nw.lat + se.lat) / 2 + "," + (nw.lng + se.lng) / 2;
    const getPath = () => {
      let str = "";

      for (let i = 0; i < circuit.path.length; i++) {
        str = str + "|" + circuit.path[i].lat + "," + circuit.path[i].long;
      }

      return str;
    };

    return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=${
      nw.lat - se.lat * 100
    }&size=150x150&path=color:0xf65e39ff|weight:2${getPath()}&key=${
      config.MapsApiKey
    }`;
  };

  function renderDifficulty() {
    switch (circuit.difficulty) {
      case 1:
        return ["Facile", "green"];
      case 2:
        return ["Moyen", "conso"];
      case 3:
        return ["difficile", "red-500"];
      default:
        return null;
    }
  }
  return (
    <>
      <Image
        src={circuit.cover_image}
        size="big"
        className="object-cover md:h-80 h-96 rounded w-full"
      />

      <div className="absolute right-0 top-0 p-5">
        <Image
          src={returnMapImage()}
          compress={false}
          className="object-cover md:h-44 md:w-44 h-22 w-22 rounded-full border-4 border-white "
        />
      </div>
      <div className="absolute inset-x-0 bottom-0 p-5">
        <div className="text-white text-xl font-marker ">{circuit.name}</div>
        <div className="flex justify-between mt-2">
          <div className="hidden md:flex bg-background p-2 rounded bg-opacity-60 items-center">
            {circuit.points.slice(0, 5).map((p, index) => (
              <Image
                src={p.cover_image}
                className={
                  "h-6 w-6 object-cover rounded-full border-2 border-white -mr-4"
                }
                size="thumb"
              />
            ))}
            <p className="ml-5">
              {circuit.points.length} lieux à visiter sur le parcours
            </p>
          </div>
          <div className="flex gap-3">
            {circuit.visit_distance ? (
              <div className="flex bg-background p-2 rounded bg-opacity-60 items-center gap-2">
                <IconDistance /> {circuit.visit_distance} km
              </div>
            ) : null}
            {circuit.visit_duration ? (
              <div className="flex bg-background p-2 rounded bg-opacity-60 items-center gap-2">
                <ClockIcon className="h-5 text-conso" />
                {(circuit.visit_duration - (circuit.visit_duration % 60)) / 60}h
                {circuit.visit_duration % 60}
              </div>
            ) : null}

            {circuit.difficulty ? (
              <div className="flex bg-background p-2 rounded bg-opacity-60 items-center gap-2">
                <div
                  className={`rounded-xl h-3 w-3 bg-${renderDifficulty()[1]}`}
                />
                {renderDifficulty()[0]}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default CircuitCard;
