// const initial = JSON.parse(localStorage.getItem('selectedGwigs')) || [];
const initial = [];

// [{ _id : "azevksdjfn", name : "Morlaix"}, {_id : "dfsdfsf", name : 'Lanmeur'}]

const userReducer = (state = initial, { type, payload }) => {
    switch (type) {
        case 'SET_GWIG_SELECTION':
            return payload
        default:
            return state;
    }
}

export default userReducer;