import React, { useState } from "react";
import axios from "axios";
import { HeartIcon } from "@heroicons/react/outline";
import { HeartIcon as SolidHeartIcon } from "@heroicons/react/solid";

const LikesNb = ({ type, likes, _id }) => {
  const [liked, setLiked] = useState(false);
  const [numOfLikes, setNumOfLikes] = useState(likes);

  const like = (e) => {
    e.stopPropagation();
    if (liked) {
      return;
    }
    axios
      .post("/public/like", {
        item: type,
        _id: _id,
      })
      .then((res) => {
        if (res.data.success) {
          setLiked(true);
          setNumOfLikes(likes + 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <button
      disabled={false}
      onClick={like}
      className="flex rounded bg-background px-2 py-2 text-conso text-md  items-center"
    >
      {liked ? (
        <SolidHeartIcon className=" h-7 mr-2" />
      ) : (
        <HeartIcon className=" h-7 mr-2" />
      )}
      {numOfLikes}
    </button>
  );
};

export default LikesNb;
