import React, { useState, useEffect } from "react";
import CircuitMenu from "./CircuitMenu";
import EventMenu from "./EventMenu";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import BusinessMenu from "./BusinessMenu";
import {
  CalendarIcon,
  LibraryIcon,
  MapIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import PointMenu from "./PointMenu";
import Sheet from "react-modal-sheet";

const SideBar = ({
  setCenter,
  setZoom,
  hoverIds,
  setHoverIds,
  setDataList,
  bounds,
  dataList,
  requestTimer,
}) => {
  let { path, url } = useRouteMatch();
  const [isOpen, setOpen] = useState(false);

  function content() {
    return (
      <Switch>
        <Route exact path={path}>
          <div className="font-marker rounded-t bg-conso  pb-3 pt-5 text-white text-md text-center h-16">
            Menu
          </div>

          <div>
            <div className=" mx-3 mt-3">
              <h1 className="text-metal text-md ">Sortir et découvrir</h1>
              <h2 className="text-sm my-2">
                Activités disponibles, événements organisés par les associations
                et les commerces, découverte du territoire...
              </h2>
              <div className="flex justify-around">
                <Link
                  className="flex items-center justify-center rounded  h-20 bg-background w-1/4"
                  to={`${url}/agenda`}
                >
                  <div className="flex items-center  text-md mx-2 flex-col">
                    <CalendarIcon className="h-5 w-5 " />
                    Agenda
                  </div>
                </Link>
                <Link
                  className="flex items-center justify-center rounded  h-20 bg-background w-1/4"
                  to={`${url}/circuits`}
                >
                  <div className="flex items-center  text-md mx-2 flex-col">
                    <MapIcon className="h-5 w-5 " />
                    Circuit
                  </div>
                </Link>
                <Link
                  className="flex items-center justify-center rounded  h-20 bg-background w-1/4"
                  to={`${url}/points`}
                >
                  <div className="flex items-center text-center text-md mx-2 flex-col">
                    <LibraryIcon className="h-5 w-5 " />
                    Point d'interêts
                  </div>
                </Link>
              </div>
              <h1 className="text-metal text-md mt-10">Shopping</h1>
              <h2 className="text-sm my-2">
                Commerçants et associations partenaires de Gwig
              </h2>
              <Link
                className="flex items-center justify-center rounded  h-20 bg-background "
                to={`${url}/commerces`}
              >
                <div className="flex items-center text-center text-md mx-2 flex-col">
                  <ShoppingCartIcon className="h-5 w-5 " />
                  Commerces
                </div>
              </Link>
            </div>
          </div>
        </Route>
        <Route path={`${path}/circuits`}>
          <CircuitMenu
            setCenter={setCenter}
            setZoom={setZoom}
            hoverIds={hoverIds}
            setHoverIds={setHoverIds}
            setDataList={setDataList}
            dataList={dataList}
            bounds={bounds}
            requestTimer={requestTimer}
          />
        </Route>

        <Route path={`${path}/agenda`}>
          <EventMenu
            setCenter={setCenter}
            setZoom={setZoom}
            hoverIds={hoverIds}
            setHoverIds={setHoverIds}
            setDataList={setDataList}
            dataList={dataList}
            bounds={bounds}
            requestTimer={requestTimer}
          />
        </Route>
        <Route path={`${path}/commerces`}>
          <BusinessMenu
            setCenter={setCenter}
            setZoom={setZoom}
            hoverIds={hoverIds}
            setHoverIds={setHoverIds}
            setDataList={setDataList}
            dataList={dataList}
            bounds={bounds}
            requestTimer={requestTimer}
          />
        </Route>
        <Route path={`${path}/points`}>
          <PointMenu
            setCenter={setCenter}
            setZoom={setZoom}
            hoverIds={hoverIds}
            setHoverIds={setHoverIds}
            setDataList={setDataList}
            dataList={dataList}
            bounds={bounds}
            requestTimer={requestTimer}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <>
      <button
        className="md:hidden fixed bottom-0 z-50 m-2 bg-conso py-3 px-4 font-marker text-white rounded"
        onClick={() => setOpen(true)}
      >
        Menu
      </button>

      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        snapPoints={[600, 400, 108, 0]}
        initialSnap={1}
      >
        <Sheet.Container
          style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
        >
          <Sheet.Content style={{ overflow: "hidden" }}>
            {content()}
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>

      <div className=" md:flex hidden flex-col fixed top-20 left-5 z-50 rounded bg-white shadow-md bottom-10 w-96 ">
        {content()}
      </div>
    </>
  );
};

export default SideBar;
