import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import GwigMap from "../../components/mapComponents/GwigMap";
import SideBar from "./SideBar";
import { Helmet } from "react-helmet";

const MapPage = () => {
  window.scrollTo(0, 0);

  const [center, setCenter] = useState(); //map center
  const [zoom, setZoom] = useState(); //map zoom
  const [bounds, setBounds] = useState(0); //map bounds
  const [hoverIds, setHoverIds] = useState([false, []]); //id of pin hovered
  const [pinClick, setPinClick] = useState(null); //id of pin clicked
  const [dataList, setDataList] = useState([]); //list of data to render on the map
  const requestTimer = 850; //time to wait before a request is fired

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez tous les événements et activités autour de vous sur la carte interactive Gwig. Marchés, balades, activités sportives... `}
        />
        <title>Gwig - Carte interactive</title>
      </Helmet>
      <Header title={"Carte"} />

      <SideBar
        setCenter={setCenter}
        setZoom={setZoom}
        hoverIds={hoverIds}
        setHoverIds={setHoverIds}
        setDataList={setDataList}
        dataList={dataList}
        bounds={bounds}
        requestTimer={requestTimer}
      />

      <GwigMap
        className="h-screen "
        dataList={dataList}
        setHoverIds={setHoverIds}
        hoverIds={hoverIds}
        setPinClick={setPinClick}
        setCenter={setCenter}
        setZoom={setZoom}
        setBounds={setBounds}
        bounds={bounds}
        center={center}
        zoom={zoom}
      />
    </>
  );
};

export default MapPage;
