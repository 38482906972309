import React from "react";
import moment from "moment";

const EventCard = ({ event, gwig }) => {
  return (
    <div className="flex bg-white rounded ">
      <img
        alt=""
        src={event.cover_image}
        size="big"
        className="w-24 h-32 object-cover rounded-l "
      />
      <div className="flex flex-col ml-3 mt-3 justify-between">
        <div className="flex flex-col">
          <p className="text-md  font-bold line-clamp-1">{event.title}</p>
          <p className="text-base text-conso">{event.location.city}</p>
          <div className="flex">
            <div className="flex rounded text-metal bg-metal bg-opacity-20 ">
              {moment(event.datetime_in).format("DD MMM") ===
              moment(event.datetime_out).format("DD MMM") ? (
                <p className="text-base mx-4">
                  {"Le "}
                  {moment(event.datetime_in).format("DD MMM")}
                </p>
              ) : (
                <p className="text-base mx-4">
                  {"Du "}
                  {moment(event.datetime_in).format("DD MMM")}
                  {" au "}
                  {moment(event.datetime_out).format("DD MMM")}
                </p>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="flex rounded text-purple bg-purple bg-opacity-20 mt-2">
              <p className="text-base mx-4">
                {"De "}
                {moment(event.datetime_in).format("HH")}
                {"h"}
                {moment(event.datetime_in).format("mm") === "00"
                  ? null
                  : moment(event.datetime_in).format("mm")}
                {" à "}
                {moment(event.datetime_out).format("HH")}
                {"h"}
                {moment(event.datetime_out).format("mm") === "00"
                  ? null
                  : moment(event.datetime_out).format("mm")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
