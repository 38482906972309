import React from "react";
import Logo from "../assets/images/logo.png";

const LoadingLogo = ({ className = "" }) => {
  return (
    <div className={"flex-col items-center justify-center w-full" + className}>
      <img
        src={Logo}
        className="h-24 animate-heart mx-auto"
        alt="loading logo"
      />
      <p className="mt-2 text-center">Chargement...</p>
    </div>
  );
};

export default LoadingLogo;
