import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import PageLayout from "../components/PageLayout";
import Image from "../components/Image";
import LoadingLogo from "../components/LoadingLogo";
import { useSelector } from "react-redux";
import { updateMairieSelection } from "../actions/selectedMairieActions";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const GwigPickerPage = () => {
  const selectedMairie = useSelector((state) => state.selectedMairie);

  const dispatch = useDispatch();

  const [mairieList, setMairieList] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!selectedMairie.name) {
      axios
        .post("/open/list/gwig", {
          params: { filter: { isClient: true }, populate: "mairie_id" },
        })
        .then((res) => {
          if (res.data.success) {
            setMairieList(res.data.data);
          }
        });
    }
  }, [selectedMairie]);

  function Click(mairie) {
    dispatch(updateMairieSelection({ mairie: mairie.mairie_id }));
    history.push({
      pathname: "/commune/" + mairie.slug,
      state: { id: mairie.mairie_id },
    });
  }

  function renderMairieList() {
    return mairieList.map((mairie, i) => (
      <button
        onClick={() => Click(mairie)}
        className="bg-white rounded flex items-center"
      >
        <Image src={mairie.cover_image} size="med" className="h-24 w-24 mr-3" />
        <p className="font-bold text-conso">Commune de {mairie.name}</p>
      </button>
    ));
  }
  if (!selectedMairie.name) {
    return (
      <PageLayout>
        <Helmet>
          <meta
            name="description"
            content="Gwig est l'application qui regroupe toute la vie municipale, commerciale et associative de votre commune. Evénements, activités, produits... tout le dynamisme local est référencé sur Gwig, l'application citoyenne des communes connectées !"
          />
          <title>Gwig - Sélectionner ma Commune</title>
        </Helmet>
        <div className="flex flex-col">
          <p className="font-bold text-lg text-conso text-center mb-8 col-span-3 col-start-3">
            Bienvenue sur Gwig !
          </p>
          <p className="font-medium mb-8 col-span-3 col-start-3">
            Choisissez votre commune de résidence ou favorite pour y avoir accès
            rapidement à toutes ses infos locales !{" "}
          </p>
          <p className="font-medium mb-8 col-span-3 col-start-3">
            Naviguez dans l'agenda, l'annuaire ou les posts pour afficher la vie
            locale de plusieurs communes à la fois !
          </p>
          <div className="grid md:grid-cols-2 gap-5 col-span-3 col-start-3">
            {renderMairieList()}
          </div>
        </div>
      </PageLayout>
    );
  }

  if (selectedMairie.name) {
    history.push({
      pathname: "/commune/" + selectedMairie.gwigs_pop[0].slug,
      state: { id: selectedMairie.id },
    });
  }
  return (
    <div className="flex justify-center h-screen items-center">
      <LoadingLogo />
    </div>
  );
};

export default GwigPickerPage;
