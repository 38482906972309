import React from "react";
import MairieBanner from "../components/MairieBanner";
import LoadingLogo from "../components/LoadingLogo";
import PageLayout from "../components/PageLayout";
import { useSelector } from "react-redux";
import useGetData from "../hooks/useGetData";
import GwigSelector from "../components/GwigSelector";
import { Link } from "react-router-dom";
import AttachmentCard from "../components/AttachmentCard";
import { Helmet } from "react-helmet";

const DemarchesPage = () => {
  window.scrollTo(0, 0);
  const { selectedMairie, selectedGwigs } = useSelector((state) => state);

  const { data: attachments, loading } = useGetData({
    url: "/open/list/attachment",
    params: {
      params: {
        filter: {
          createdBy: selectedMairie._id,
        },
      },
    },
  });

  function renderattachments() {
    return attachments.map((attachment, i) => (
      <AttachmentCard attachment={attachment} key={i} />
    ));
  }

  return (
    <PageLayout loading={false}>
      <Helmet>
        <meta
          name="description"
          content={`Retrouvez toutes les démarches en ligne de la ${selectedMairie.name}`}
        />
        <title>Gwig - Démarches {selectedMairie.gwigs_pop[0].name}</title>
      </Helmet>
      <div className=" flex flex-col w-full">
        <MairieBanner mairie={selectedMairie} variant="change" />
        <GwigSelector />
        {attachments.length ? (
          <>
            <div className="flex font-bold text-md my-5 w-full">Démarches</div>
            <div className="flex flex-col gap-2 sm:gap-5 ">
              {loading ? <LoadingLogo /> : renderattachments()}
            </div>
          </>
        ) : (
          <div>Aucune Démarche</div>
        )}
      </div>
    </PageLayout>
  );
};

export default DemarchesPage;
