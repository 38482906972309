import React from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";

const BackButton = () => {
  const history = useHistory();
  return (
    <button
      onClick={() => history.goBack()}
      className="rounded bg-white flex p-5 md:my-5 my-3 items-center font-medium text-metal w-28"
    >
      <ArrowLeftIcon className="h-5 text-metal mr-2" />
      Retour
    </button>
  );
};

export default BackButton;
