import Image from "./Image";
import LikesNb from "./LikesNb";

const ProductPanel = ({ data }) => {
  let stopropagation = (e) => {
    e.stopPropagation();
  };
  console.log(data);

  return (
    <div
      className="flex flex-col bg-white md:rounded shadow-xl "
      onClick={stopropagation}
    >
      <div className="relative ">
        <Image
          src={data.cover_image}
          size="big"
          className=" md:rounded-t  w-full  object-cover max-h-96"
        />
        {data.price > 0 ? (
          <div className="absolute rounded bg-white bottom-5 left-5 px-3 py-2 text-conso font-bold text-md items-center">
            {data.price} €
          </div>
        ) : null}
      </div>
      <div className="flex flex-col justify-between px-5 py-5 ">
        <p className="text-lg font-bold mt-3">{data.name}</p>
        <p className=" mt-3">{data.description}</p>
        <div className="flex mt-3">
          <LikesNb type="product" likes={data.likes_nb} _id={data._id} />
        </div>
      </div>
    </div>
  );
};

export default ProductPanel;
