import React, { useState } from "react";
import moment from "moment";
import { GwigMapPin } from "../../assets/svg/GwigMapPin";
import CreatedByDisplay from "../CreatedByDisplay";
import Marker from "./Marker";

const GwigMapPointCard = ({
  item,
  nb,
  setCenter,
  setZoom,
  hoverIds,
  setHoverIds,
}) => {
  const setCenterAndZoom = () => {
    setCenter({
      lat: item.location.lat,
      lng: item.location.long,
    });
  };

  return (
    <>
      <button
        onClick={() => setCenterAndZoom()}
        onMouseEnter={() => {
          if (item.isGroup) {
            setHoverIds([true, item._id]);
          } else {
            setHoverIds([true, [item._id]]);
          }
        }}
        onMouseLeave={() => setHoverIds([false, null])}
        className={
          hoverIds[0] === true && hoverIds[1] === item._id
            ? " flex mb-4 bg-background rounded w-full"
            : "flex mb-4 hover:bg-background rounded  w-full"
        }
      >
        <div
          className={` ${
            hoverIds[0] && hoverIds[1].includes(item._id)
              ? "animate-bounce"
              : ""
          }`}
        >
          <Marker key={nb} />
        </div>
        <div className="flex-col">
          <p className="text-left font-bold line-clamp-1 w-80">{item.name}</p>

          <CreatedByDisplay
            business={item.createdBy}
            showPicture={false}
            nameClassName={"text-sm"}
            clickable={false}
          />
        </div>
      </button>
    </>
  );
};

export default GwigMapPointCard;
