import React from "react";
import { Link } from "react-router-dom";
import { ChatIcon } from "@heroicons/react/outline";
import { CalendarIcon } from "@heroicons/react/outline";
import { BookOpenIcon } from "@heroicons/react/outline";
import { LibraryIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetMairieSelection } from "../actions/selectedMairieActions";
import { useHistory } from "react-router-dom";

const SideBar = () => {
  const selectedMairie = useSelector((state) => state.selectedMairie);
  const dispatch = useDispatch();
  const history = useHistory();
  if (selectedMairie.name) {
    return (
      <div className="hidden md:flex flex-col ">
        <button
          className={
            "flex items-center  rounded h-10 bg-conso my-2 relative ml-1"
          }
          onClick={() => {
            dispatch(resetMairieSelection());
            history.push({
              pathname: "/",
            });
          }}
        >
          <div className="flex bg-white rounded h-full w-10 justify-center items-center -ml-1">
            <RefreshIcon className="h-6 text-conso" />
          </div>
          <p className="text-white text-center ml-2 mr-4 font-bold">
            Changer de commune
          </p>
        </button>
        <Link
          className="flex my-2"
          to={"/posts/" + selectedMairie.gwigs_pop[0].slug}
        >
          <div className="flex rounded bg-accent-logo justify-center items-center h-10 w-10">
            <ChatIcon className="text-white h-6 w-6" />
          </div>
          <div className="flex text-accent-logo items-center text-md ml-2">
            Actualités
          </div>
        </Link>
        <Link
          className="flex my-2"
          to={"/evenements/" + selectedMairie.gwigs_pop[0].slug}
        >
          <div className="flex rounded bg-salmon justify-center items-center h-10 w-10">
            <CalendarIcon className="text-white h-6 w-6" />
          </div>
          <div className="flex text-salmon items-center text-md ml-2">
            Agenda
          </div>
        </Link>
        <Link
          className="flex my-2"
          to={"/annuaire/" + selectedMairie.gwigs_pop[0].slug}
        >
          <div className="flex rounded bg-pink justify-center items-center h-10 w-10">
            <BookOpenIcon className="text-white h-6 w-6" />
          </div>
          <div className="flex text-pink items-center text-md ml-2">
            Annuaire
          </div>
        </Link>
        <Link
          className="flex my-2"
          to={"/patrimoine/" + selectedMairie.gwigs_pop[0].slug}
        >
          <div className="flex rounded bg-yellow justify-center items-center h-10 w-10">
            <LibraryIcon className="text-white h-6 w-6" />
          </div>
          <div className="flex text-yellow items-center text-md ml-2">
            Patrimoine
          </div>
        </Link>
      </div>
    );
  }
  return null;
};

export default SideBar;
