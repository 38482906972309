import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import GwigMapPointCard from "../../components/mapComponents/GwigMapPointCard";
import MenuHeader from "../../components/mapComponents/MenuHeader";

const PointMenu = ({
  setCenter,
  setZoom,
  hoverIds,
  setHoverIds,
  setDataList,
  bounds,
  dataList = [],
  requestTimer,
}) => {
  const [loading, setLoading] = useState(false);
  const firstLoad = useRef(true);

  function requete() {
    if (bounds?.nw?.lat) {
      axios
        .post("/open/list/point", {
          params: {
            filter: {
              "location.lat": {
                $lte: bounds.ne.lat,
                $gte: bounds.se.lat,
              },
              "location.long": {
                $lte: bounds.ne.lng,
                $gte: bounds.nw.lng,
              },
            },
            sort: "location.lat",
            populate: "createdBy",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setDataList(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return requete();
    }
    setLoading(true);
    const timer = setTimeout(() => {
      requete();
    }, requestTimer);
    return () => clearTimeout(timer);
  }, [bounds]);

  function renderBusiness() {
    if (dataList.length) {
      return dataList.map((item, i) => {
        return (
          <>
            <GwigMapPointCard
              key={i}
              item={item}
              nb={i}
              setCenter={setCenter}
              setZoom={setZoom}
              hoverIds={hoverIds}
              setHoverIds={setHoverIds}
            />
          </>
        );
      });
    }
    return (
      <>
        <p>aucun Commerce dans cette zone</p>
      </>
    );
  }

  return (
    <div className="h-full">
      <MenuHeader
        loading={loading}
        setDataList={setDataList}
        title="Points d'interêts"
      />
      <div
        className="flex-col mb-3 px-2 overflow-y-auto overflow-x-hidden mt-3"
        style={{ height: "calc(100% - 84px)" }}
      >
        {renderBusiness()}
      </div>
    </div>
  );
};

export default PointMenu;
