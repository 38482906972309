export const updateMairieSelection = ({ mairie, onSuccess = function () { } }) => (dispatch) => {

    localStorage.setItem("selectedMairie", JSON.stringify(mairie));

    dispatch({
        type: "SET_MAIRIE_SELECTION",
        payload: mairie
    })
}

export const resetMairieSelection = () => (dispatch) => {
    localStorage.removeItem("selectedMairie");

    dispatch({
        type: "RESET_MAIRIE_SELECTION"
    })
}