import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import Image from "./Image";
import config from "../config";
import { ArrowsExpandIcon } from "@heroicons/react/outline";

// import "./map.scss";

var map;
var maps;
var polyline;

const CircuitMap = ({
  circuit,
  className = "w-full h-full  rounded-l overflow-hidden relative",
  options = { fullscreenControl: false },
}) => {
  const mapRef = useRef(null);
  // Set default center to user address coords or default to Plougasnou
  const [center, setCenter] = useState({
    lat: 48.705,
    lng: -3.828,
  });
  const [zoom, setZoom] = useState(13);
  const points = circuit.points;
  const path = circuit.path;
  const [mapFullScreen, setMapFullScreen] = useState(false);

  // Bind maps' objects to global
  const bindMaps = (_map, _maps) => {
    map = _map;
    maps = _maps;
    polyline = new maps.Polyline({
      path: [],
      strokeColor: "#f65e39",
      geodesic: false,
    });
    return polyline.setMap(map);
  };

  const setCenterAndZoom = () => {
    const bounds = {
      nw: {
        lat: Math.max(...path.map((i) => i.lat)),
        lng: Math.min(...path.map((i) => i.long)),
      },
      se: {
        lat: Math.min(...path.map((i) => i.lat)),
        lng: Math.max(...path.map((i) => i.long)),
      },
    };

    const size = {
      width: mapRef.current.offsetWidth,
      height: mapRef.current.offsetHeight,
    };

    const { center, zoom } = fitBounds(bounds, size);
    setCenter(center);
    setZoom(zoom);
  };

  useEffect(() => {
    if (polyline) {
      drawPolyline();
    }
  }, [circuit]);

  // First render of polygons on the map
  const drawPolyline = () => {
    // Clear polyline
    polyline.getPath().clear();

    for (let i in path) {
      polyline.getPath().push(new maps.LatLng(path[i].lat, path[i].long));
    }

    // if first render, set zoom
    if (path.length) setCenterAndZoom();
  };

  const renderPointsOnMap = () => {
    // Array containing _ids only
    // Filter points Object (that contains all the info of point) based on points _id selected
    let _points = points;

    return _points.map((item, i) => (
      <Marker
        key={i}
        lat={item.location?.lat}
        lng={item.location?.long}
        image={item.cover_image}
        text={item.name}
      />
    ));
  };

  return (
    <div
      className={mapFullScreen ? "fixed inset-0 z-30" : className}
      ref={mapRef}
    >
      {mapFullScreen ? (
        <div className="fixed top-5 left-5 z-50 flex justify-between right-5">
          <div />
          <button
            type="button"
            onClick={() => setMapFullScreen(false)}
            className="rounded bg-white px-3 h-12 font-medium text-pro border-pro border-2"
          >
            Fermer le plein écran
          </button>
        </div>
      ) : (
        <button
          className="rounded bg-white h-12 w-12 font-medium text-pro border-pro border-2 absolute z-30 top-3 right-3 flex items-center justify-center"
          onClick={() => setMapFullScreen(true)}
          type="button"
        >
          <ArrowsExpandIcon className="w-6" />
        </button>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.MapsApiKey,
        }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={options}
        onGoogleApiLoaded={({ map, maps }) => {
          // Make those available globally
          bindMaps(map, maps);
          return drawPolyline();
        }}
      >
        {renderPointsOnMap()}
      </GoogleMapReact>
    </div>
  );
};

const Marker = ({ image, text }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex" onClick={() => setShow(!show)}>
      <Image
        src={image}
        size="thumb"
        className="rounded-full border-white border-4 w-14 h-14"
      />
      {show ? (
        <div className="text-base whitespace-nowrap p-3 bg-white rounded flex items-center">
          {text}
        </div>
      ) : null}
    </div>
  );
};

export default CircuitMap;
