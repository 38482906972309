const ImageFade = ({ className }) => {
  return (
    <div
      className={"absolute w-full h-full " + className}
      style={{
        backgroundImage:
          "linear-gradient(to top, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 50%)",
      }}
    />
  );
};

export default ImageFade;
