import axios from "axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateGwigsSelection } from "../actions/selectedGwigsActions";
import useGetData from "../hooks/useGetData";
import { PlusIcon } from "@heroicons/react/solid";

const GwigSelector = () => {
  const { selectedGwigs, selectedMairie } = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(selectedGwigs.length > 1);
  const dispatch = useDispatch();

  const { data: gwigs, loading } = useGetData({
    url: "/open/list/gwig",
    params: { params: { sort: "-isClient" } },
    bypassDep: true,
  });

  function addOrRemove(item, array) {
    const _item = JSON.stringify(item);
    const _array = array.map((i) => JSON.stringify(i));
    return _array.includes(_item)
      ? array.filter((i) => JSON.stringify(i) !== _item)
      : [...array, item];
  }

  function renderGwigOptions() {
    if (loading) return "Patientez...";
    return gwigs
      .filter((i) => i._id !== selectedMairie.gwigs[0])
      .map((gwig, i) => (
        <button
          onClick={() =>
            dispatch(
              updateGwigsSelection({
                selection: addOrRemove(
                  { name: gwig.name, _id: gwig._id },
                  selectedGwigs
                ),
              })
            )
          }
          key={i}
          className="bg-background rounded-full  py-2 pl-2 pr-3 font-medium inline-flex items-center mr-2 mb-2 text-sm"
        >
          <div className="bg-white rounded-full h-5 w-5 mr-2 relative">
            {selectedGwigs
              .map((i) => JSON.stringify(i))
              .includes(JSON.stringify({ name: gwig.name, _id: gwig._id })) ? (
              <div className="bg-conso absolute h-4 w-4 rounded-full left-0.5 top-0.5"></div>
            ) : null}
          </div>
          {gwig.name}
        </button>
      ));
  }

  function renderSelectionName() {
    const _array = [
      ...selectedGwigs.map((i) => i.name),
      selectedMairie.gwigs_pop[0]?.name,
    ];
    return _array.map(
      (name, i) => name + (i !== _array.length - 1 ? ", " : "")
    );
  }

  return (
    <div className="bg-white rounded p-5 mt-5 w-full">
      <div
        className={`flex flex-col sm:flex-row gap-3 justify-between ${
          isOpen ? "mb-5" : ""
        }`}
      >
        <div className="">
          <p className="text-metal">Résultats pour :</p>
          <p className="font-medium"> {renderSelectionName()}</p>
        </div>
        <button
          className="flex items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex mr-2 h-7 w-7 bg-conso text-white rounded-md items-center justify-center">
            <PlusIcon className="h-6" />
          </div>
          <p className=" text-conso sm:whitespace-nowrap font-medium">
            Inclure d'autres communes
          </p>
        </button>
      </div>
      {isOpen ? <div>{renderGwigOptions()}</div> : null}
    </div>
  );
};
export default GwigSelector;
