import React, { useState } from "react";
import useGetData from "../hooks/useGetData";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { ChevronDownIcon } from "@heroicons/react/solid";
import PostCard from "./PostCard";
import EventCard from "../components/EventCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ProductCard from "./ProductCard";
import Masonry from "react-masonry-css";

const BusinessContentElement = ({
  type,
  filter,
  initialNumber,
  title,
  isExpandable,
}) => {
  const [numberToRender, setnumberToRender] = useState(initialNumber);
  const selectedMairie = useSelector((state) => state.selectedMairie);
  const location = useLocation();

  const bid = location.pathname.split("/")[2];

  const { data: datas } = useGetData({
    url: "/open/list/" + type,
    params: {
      params: {
        filter: {
          createdBy: filter.createdBy,
          isActivity: filter.isActivity,
          datetime_in: filter.datetime_in,
        },
        populate: "createdBy",
        sort: "-publishDate",
        limit: numberToRender,
      },
    },
    dependencies: [numberToRender],
  });

  function Voirplusbutton() {
    setnumberToRender(10);
  }
  function Voirmoinsbutton() {
    setnumberToRender(initialNumber);
  }

  if (datas[0]) {
    return (
      <>
        <div className="flex justify-between">
          <p className="flex font-bold text-md">{title}</p>
          {isExpandable ? (
            numberToRender <= 2 ? (
              <button
                onClick={Voirplusbutton}
                className="flex text-metal text-md underline items-center"
              >
                <ChevronDownIcon className="h-8" />
                Voir tout
              </button>
            ) : (
              <button
                onClick={Voirmoinsbutton}
                className="flex text-metal text-md underline items-center"
              >
                <ChevronUpIcon className="h-8" />
                Voir moins
              </button>
            )
          ) : null}
        </div>
        {datas.map((d, i) =>
          type === "product" ? null : (
            <div className="flex flex-col" key={i}>
              {type === "post" ? <PostCard post={d} cardsize="small" /> : null}
              {type === "event" ? (
                <div className="grid md:grid-cols-2 gap-2">
                  <Link to={`/evenement/${d._id}`}>
                    <EventCard cardsize="small" event={d} />
                  </Link>
                </div>
              ) : null}
            </div>
          )
        )}
        <Masonry
          breakpointCols={{ 767: 1, default: 2 }}
          className="flex gap-3"
          columnClassName="flex flex-col gap-3"
        >
          {datas.map((d) => (
            <div className="flex flex-col">
              {type === "product" ? (
                <Link
                  to={{
                    pathname: "/view/product/" + d._id,
                    state: { background: location, data: d },
                  }}
                >
                  <ProductCard product={d} />
                </Link>
              ) : null}
            </div>
          ))}
        </Masonry>
      </>
    );
  }

  return null;
};

export default BusinessContentElement;
