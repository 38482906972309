import useGetData from "../hooks/useGetData";
import React, { useState, useEffect } from "react";
import Image from "./Image";

const Modal = ({ func, item }) => {
  let stopropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 z-50  md:flex bg-black bg-opacity-50 justify-center md:items-center items-end md:p-5   "
      onClick={() => {
        func(false);
      }}
    >
      {item.cover_image ? (
        <Image
          alt=""
          src={item.cover_image}
          size="big"
          className="h-5/6 object-contain rounded mt-5 "
        />
      ) : null}
    </div>
  );
};

export default Modal;
