import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ChatIcon } from "@heroicons/react/outline";
import { CalendarIcon } from "@heroicons/react/outline";
import { BookOpenIcon } from "@heroicons/react/outline";
import { LibraryIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { resetMairieSelection } from "../actions/selectedMairieActions";
import ImageFade from "./ImageFade";
import Image from "./Image";

const MairieBanner = ({ mairie, tab, variant }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className="bg-white max-w-lg  md:mt-4 md:rounded w-full">
      <div className="relative  flex justify-end  w-full">
        <Image
          src={mairie.cover_image}
          size="big"
          className="h-56 object-cover md:rounded-t w-full"
        />

        {/* TODO probleme avec Chrome */}
        <ImageFade />
        <div className="absolute ">
          <button
            className={
              "flex md:hidden items-center  rounded  mt-3 mr-3 h-8 bg-conso text-white " +
              (variant === "change" ? null : "hidden")
            }
            onClick={() => {
              dispatch(resetMairieSelection());
              history.push({
                pathname: "/",
              });
            }}
          >
            <div className="flex text-center items-center mx-2 ">
              <RefreshIcon className="h-5 mr-1" />
              Changer de commune
            </div>
          </button>
        </div>
        <NavLink
          to={"/commune/" + mairie.gwigs_pop[0].slug}
          className="absolute text-white text-center w-full -bottom-0 mb-1 text-lg font-marker "
        >
          {mairie.name}
        </NavLink>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col md:hidden">
          <div className="flex flex-col  ">
            <div className="flex mt-3 justify-evenly">
              <NavLink
                className="flex items-center rounded   h-20 bg-background"
                to={"/posts/" + mairie.gwigs_pop[0].slug}
                activeClassName="text-purple"
              >
                <div className="flex items-center text-center w-24 text-md mx-2 flex-col">
                  <ChatIcon className="h-5 w-5 " />
                  Actualités
                </div>
              </NavLink>

              <NavLink
                className="flex items-center rounded   h-20 bg-background"
                to={"/evenements/" + mairie.gwigs_pop[0].slug}
                activeClassName="text-purple"
              >
                <div className="flex items-center text-center w-24 text-md mx-2 flex-col">
                  <CalendarIcon className="h-5 w-5 " />
                  Agenda
                </div>
              </NavLink>

              <NavLink
                className="flex items-center rounded  h-20 bg-background"
                to={"/annuaire/" + mairie.gwigs_pop[0].slug}
                activeClassName="text-purple"
              >
                <div className="flex items-center text-center w-24 text-md mx-2 flex-col">
                  <BookOpenIcon className="h-5 w-5 " />
                  Annuaire
                </div>
              </NavLink>
            </div>
            <NavLink
              className="flex items-center rounded mx-3 mt-3 h-20 bg-background"
              to={"/patrimoine/" + mairie.gwigs_pop[0].slug}
              activeClassName="text-purple"
            >
              <div className="flex items-center text-center text-md mx-auto flex-col ">
                <LibraryIcon className="h-5 w-5 " />
                Patrimoine
              </div>
            </NavLink>
          </div>
        </div>
        <div className="md:flex justify-center mt-4 w-full hidden">
          <NavLink
            className="flex items-center rounded mx-2 h-20 bg-background"
            to={"/posts/" + mairie.gwigs_pop[0].slug}
            activeClassName="text-purple"
          >
            <div className="flex items-center text-center text-md mx-2 flex-col">
              <ChatIcon className="h-5 w-5 " />
              Actualités
            </div>
          </NavLink>

          <NavLink
            className="flex items-center rounded mx-2 h-20 bg-background"
            to={"/evenements/" + mairie.gwigs_pop[0].slug}
            activeClassName="text-purple"
          >
            <div className="flex items-center text-md mx-2 flex-col">
              <CalendarIcon className="h-5 w-5 " />
              Agenda
            </div>
          </NavLink>

          <NavLink
            className="flex items-center rounded mx-2 h-20 bg-background"
            to={"/annuaire/" + mairie.gwigs_pop[0].slug}
            activeClassName="text-purple"
          >
            <div className="flex items-center text-md mx-2 flex-col">
              <BookOpenIcon className="h-5 w-5 " />
              Annuaire
            </div>
          </NavLink>

          <NavLink
            className="flex items-center rounded mx-2 h-20 bg-background"
            to={"/patrimoine/" + mairie.gwigs_pop[0].slug}
            activeClassName="text-purple"
          >
            <div className="flex items-center text-md mx-2 flex-col ">
              <LibraryIcon className="h-5 w-5 " />
              Patrimoine
            </div>
          </NavLink>
        </div>
        <div
          className={
            "flex text-center justify-center font-sans text-base mx-10 italic  mt-6 " +
            (variant === "add" ? null : "hidden")
          }
        >
          Les résultats affichés sont liés à {mairie.location.city}. Vous pouvez
          ajouter d’autres communes qui vous intéressent.
        </div>
        <div
          className={
            "flex flex-col text-center font-sans text-base mx-10   mt-6 " +
            (variant === "change" ? null : "hidden")
          }
        >
          <div className="hidden md:flex justify-center ">
            <div>
              {mairie.website_links ? (
                <>
                  {" "}
                  <a
                    className="underline"
                    href={mairie.website_links}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Site internet
                  </a>{" "}
                  •{" "}
                </>
              ) : null}
            </div>
            <div className="ml-1">{mairie.phone}</div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className={
              "flex items-center justify-center rounded mt-4 mb-6  h-8 bg-conso text-white " +
              (variant === "add" ? null : "hidden")
            }
            onClick={() => {
              localStorage.removeItem("selectedMairie");
              history.push({
                pathname: "/",
              });
            }}
          >
            <div className="md:flex text-center hidden mx-2">
              Ajouter d'autres communes
            </div>
            <div className="md:hidden flex text-center mx-2 ">
              + Ajouter des communes
            </div>
          </button>
          <button
            className={
              "hidden md:flex items-center justify-center rounded mt-4 mb-4  h-8 bg-conso text-white " +
              (variant === "change" ? null : "md:hidden")
            }
            onClick={() => {
              dispatch(resetMairieSelection());
              history.push({
                pathname: "/",
              });
            }}
          >
            <div className="flex text-center mx-2 ">Changer de commune</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MairieBanner;
