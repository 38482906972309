import React from "react";
import Image from "./Image";

const BigMapImage = ({ address, className, x, y }) => {
  return (
    <a
      href={"http://maps.google.com/?q=" + address?.full}
      target="_blank"
      rel="noreferrer"
      className={" w-full   rounded-t " + className}
    >
      <Image
        compress={false}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${
          address.lat + "," + address.long
        }&zoom=11&size=${x}x${y}&scale=2${
          address.lat + "," + address.long
        }&key=AIzaSyDy7AZJBe0AR629fLGrBoktozU6QN73x_c`}
        className="rounded-t w-full "
      />
    </a>
  );
};

export default BigMapImage;
